<template>
  <div class="home">
    <div class="nadpisBlock">
      <div class="nadpisBox">
        <div class="mrakNadpisNadpis">
          <!-- <img src="@/assets/mrak-nadpis.png" alt="background nadpis" class="imgbg"/> -->
          <img src="@/assets/logoNadpis.jpg" alt="logo nadpis" class="" />
        </div>
        <div>
        </div>
        <!-- <div class="nadpis">
          <p>
            Chceme učit Brno pořádně <br />
            Vzdělání je zábava, ne povinnost
          </p>
        </div> -->
      </div>
      <!-- <div class="mrakNadpisImg">
        <img src="@/assets/mrak-homepage-brno.png" alt="background nadpis" />
      </div> -->
    </div>

    <div class="onas">
      <div class="mrakNadpis">
        <h1>O NÁS</h1>
      </div>
      <div v-html="obsah[0].homeONas"></div>
      <v-row no-gutters style="margin-top: 50px;">

        <v-col cols="12" md="6" align="center" justify="center" class="pa-2" style="display: flex; flex-direction: column;">
          <div class="workshopyNadpisImgBox">
            <div class="mrakNadpis_1">
              <div>WORKSHOPY & SEMINÁŘE</div>
            </div>
          </div>
          <div v-html="obsah[0].homeWorkshopy" class="growColumn"></div>
           <router-link to="/workshopy-seminare"><button>zjistit více</button> </router-link>
        </v-col>

        <v-col cols="12" md="6" align="center" justify="center" class="pa-2" style="display: flex; flex-direction: column;">
          <div class="mentoringNadpisImgBox">
            <div class="mrakNadpis_1">
              <div>JAZYKOVÝ MENTORING</div>
            </div>
          </div>
          <div v-html="obsah[0].homeMentoring" class="growColumn"></div>
          <router-link to="/jazykovy-mentoring"><button>zjistit více</button></router-link>
        </v-col>

      </v-row>

    </div>
    <div class="mrakNadpis">
      <div>LEKTOŘI</div>
    </div>
    <div class="carouselBox">
      <Carousel />
    </div>
  </div>
</template>


<script>
import axios from "axios";
import Carousel from "@/components/Carousel.vue";
export default {
  name: "Home",
  components: {
    Carousel,
  },
  created() {
    this.loadObsah();
    document.title = this.$route.meta.title;
  },
  data() {
    return {
      obsah: [],
    };
  },
  methods: {
     loadObsah() {
         axios.post(this.$myServerPath + "/loadObsah.php",{
        table: "Home"
      })
      .then((response) => {
        this.obsah = response.data;
      });
    },
  },
};
</script>

<style>
.home h1{
  font-size: 32px;
}
.home .nadpisBox {
  position: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.home .mrakNadpis .imgbg {
  position: absolute;
  height: 100%;
  left: 0;
  z-index: 0;
}
.home .nadpis{
  position: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute;
  left: 50px;
  width: 240px; */
  z-index: 2;
}
.home .nadpisBox img {
  position: flex;
  justify-content: center;
  align-items: center;
  /* width: 280px;
  margin: 5px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.5) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
}
.home .nadpisBox .nadpis {
   background: center / contain no-repeat url("../assets/ucitbrno_bg_mrak01.png");
  /* width: 320px;
  font-size: 24px;
  padding: 5px;
  color: #fff;
  background-color: var(--fialova);
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.5) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
}

.home .mrakNadpisImg Img {
  position: absolute;
  top: 40px;
  right: 0;
  height: 400px;
  z-index: 1;
}
.home .onas {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 600px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}
.home .onas p {
  padding: 30px;
}
.home .mrakNadpisNadpis {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 500px;
  margin: 0 auto 0 auto;
  font-size: 32px;
  font-family: SemiBold;
  color: #fff;
  background: center / contain no-repeat url("../assets/ucitbrno_bg_mrak01.png");
}
.home .mrakNadpisNadpis img {
  width: 450px;
}
.home .mrakNadpis {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 100px;
  margin: 60px auto 30px auto;
  font-size: 32px;
  font-family: SemiBold;
  color: #fff;
  background: center / contain no-repeat url("../assets/ucitbrno_bg_mrak01.png");
}
.home .workshopyNadpisImgBox {
  width: auto;
  height: 320px;
  background: center / contain no-repeat
    url("../assets/mrak-workshopy-seminare.png");
}
.home .mentoringNadpisImgBox {
  width: auto;
  height: 320px;
  background: center / contain no-repeat
    url("../assets/mrak-jazykovy-mentoring.png");
}
.home .mrakNadpis_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 550px;
  height: 100px;
  background: center / 100% 90% no-repeat
    url("../assets/ucitbrno_bg_mrak01.png");
}
.home .mrakNadpis_1 div {
  font-size: 32px;
  font-family: SemiBold;
  color: #fff;
}
.carousel {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.carouselBox .mrakNadpis {
  margin-left: auto;
  margin-right: auto;
}
.home .carouselBox {
  /* height: 600px; */
  width: 100%;
  background: center / 100% 100% no-repeat
    url("../assets/mrak-velky-fialovy-end.png");
}

@media screen and (max-width: 1100px) {
  .carouselBox {
    background: center / 180% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
}
@media screen and (max-width: 950px) {
  .home .nadpisBox .mrakNadpis {
  background: none;
}
  .home .mrakNadpis .imgbg {
  display: none;
}
.home .mrakNadpisNadpis {
  width: 700px;
}
.home .mrakNadpisNadpis img {
  width: 350px;
}
}
@media screen and (max-width: 768px) {
  .home .mrakNadpisNadpis {
  width: 500px;
}
.home .mrakNadpisNadpis img {
  width: 250px;
}
}
@media screen and (max-width: 576px) {
    .home .mrakNadpisNadpis {
  width: 360px;
  background-size: 500px 300px;
}
.home .mrakNadpisNadpis img {
  width: 250px;
}
  .home .nadpisBox {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 150px;
    left: 2%; */
  }
  .home .onas p {
    padding: 0 10px;
  }
  .home .mrakNadpis,
  .home .mrakNadpis_1 {
    width: 300px;
    height: 100px;
    background: center / 100% 90% no-repeat
      url("../assets/ucitbrno_bg_mrak01.png");
  }
  .home .mrakNadpis div,
  .home .mrakNadpis_1 div {
    font-size: 20px;
  }
  .carouselBox {
    background: center / 200% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
  .carouselBox .mrakNadpis {
    width: 300px;
    height: 100px;
    background: center / 100% 90% no-repeat
      url("../assets/ucitbrno_bg_mrak01.png");
  }
}
</style>