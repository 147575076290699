<template>
  <div class="mentoring">
    <div class="nadpisBlock">
      <div class="mrakNadpis">
        <!-- <img src="@/assets/header_jazykovymentoring.png"alt="background nadpisu" /> -->
        <h1 class="nadpis">JAZYKOVÝ MENTORING</h1>
      </div>
    </div>

    <div class="mentoringPage">
      <div class="mrakNadpis_1">
        <div v-html="obsah[0].podnadpis1"></div>
      </div>

      <v-row no-gutters class="podnadpis_1" justify="center" align="start">
        <v-col cols="12" md="5" class="ma-4 pa-4">
          <div>
            <p v-html="obsah[0].sloupec1"></p>
          </div>
        </v-col>
        <v-col cols="12" md="5" class="ma-4 pa-4">
          <div>
            <p v-html="obsah[0].sloupec2"></p>
          </div>
        </v-col>
      </v-row>

         <div class="mrakNadpis_1">
        <div style="font-size: 30px">PŘÍPADOVÉ STUDIE</div>
      </div>
      <v-row no-gutters class="pripadoveStudie">
        <v-col cols="12" lg="4" class="pa-4">
          <div class="pripadovaStudieNadpis">
            <h2 v-html="obsah[0].nadpisA"></h2>
            <p v-html="obsah[0].klientA"></p>
          </div>
        </v-col>
        <v-col cols="12" lg="4" class="pa-4">
          <div class="pripadovaStudieNadpis">
            <h2 v-html="obsah[0].nadpisB"></h2>
            <p v-html="obsah[0].klientB"></p>
          </div>
        </v-col>
        <v-col cols="12" lg="4" class="pa-4">
          <div class="pripadovaStudieNadpis">
            <h2 v-html="obsah[0].nadpisC"></h2>
            <p v-html="obsah[0].klientC"></p>
          </div>
        </v-col>
      </v-row>

      <div class="mentoringBlok">
        <div class="mentoringBox">
          <div class="mentoringPanely">
            <div class="mentoringBoxLevy">
              <img
                :src="`${$myServerPath}/JazykovyMentoringIcons/${obsah[0]['bannerIcon1']}`"
                alt="ikona banneru 1"
              />
              <h4 v-html="obsah[0].bannerText1"></h4>
            </div>
            <div class="mentoringBoxStred">
              <img
                :src="`${$myServerPath}/JazykovyMentoringIcons/${obsah[0]['bannerIcon2']}`"
                alt="ikona banneru 2"
                style="height: 80px"
              />
              <h4 v-html="obsah[0].bannerText2"></h4>
            </div>
            <div class="mentoringBoxPravy">
              <img
                :src="`${$myServerPath}/JazykovyMentoringIcons/${obsah[0]['bannerIcon3']}`"
                alt="ikona banneru 3"
              />
              <h4 v-html="obsah[0].bannerText3"></h4>
            </div>
          </div>
        </div>
      </div>

      <div class="mrakNadpis_1">
        <div v-html="obsah[0].podnadpis2"></div>
      </div>
      <div v-html="obsah[0].listText"></div>

   

    </div>

    <div class="mrakNadpis_1">
      <div>REFERENCE</div>
    </div>
    <div class="carouselBoxMentoring">
      <CarouselReferenceMentoring />
    </div>

    <div class="mrakNadpis_1">
      <div>POPTÁVKA</div>
    </div>

    <div class="poptavka">
      <div class="formPoptavka">
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">JMÉNO</div>
          <div class="formInput">
            <input v-model="jmeno" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">FIRMA</div>
          <div class="formInput">
            <input v-model="firma" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">EMAIL</div>
          <div class="formInput">
            <input v-model="email" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">TELEFON</div>
          <div class="formInput">
            <input v-model="phone" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="start" justify-md="end">
          <div class="formLabel textLabel">ZPRÁVA</div>
          <div class="formInput">
            <textarea v-model="poznamka" type="text"></textarea>
          </div>
        </v-row>
        <button v-on:click="odeslat" class="formButton">odeslat</button>
      </div>
    </div>
    <v-dialog v-model="dialog" width="360">
      <v-card>
        <v-card-text class="card_text">
          {{ dialogText }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import CarouselReferenceMentoring from "@/components/CarouselReferenceMentoring.vue";
export default {
  name: "Mentoring",
  components: {
    CarouselReferenceMentoring,
  },
  created() {
    this.loadObsah();
    document.title = this.$route.meta.title;
  },
  data() {
    return {
      obsah: [],

      firma: "",
      jmeno: "",
      email: "",
      phone: "",
      poznamka: "",

      dialog: false,
      dialogText: "",
    };
  },

  methods: {
    loadObsah() {
      axios
        .post(this.$myServerPath + "/loadObsah.php", {
          table: "JazykovyMentoring",
        })
        .then((response) => {
          this.obsah = response.data;
        });
    },
    odeslat() {
      if (this.jmeno == "" || this.email == "") {
        this.dialogText =
          "Prosím vyplňte alespoň jméno kontaktní osoby a e-mail.";
        this.dialog = true;
      } else
        axios
          .post(this.$myServerPath + "/sendMail.php", {
            firma: this.firma,
            jmeno: this.jmeno,
            email: this.email,
            phone: this.phone,
            poznamka: this.poznamka,
          })
          .then(() => {
            this.dialogText = "Formulář byl odeslán.";
            this.dialog = true;
            this.firma = "";
            this.jmeno = "";
            this.email = "";
            this.phone = "";
            this.poznamka = "";
          });
    },
  },
};
</script>

<style scoped>
.mentoring {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 220px;
}
.nadpisBox {
  position: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.nadpisBox .nadpis {
  position: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute;
  top: 380px;
  left: 50%;
  right: 50%; */
  /* text-align: left; */
  font-size: 48px;
  line-height: 1;
  padding: 5px;
  color: #fff;
  z-index: 2;
  transform: translate(0, -120px);
}
.mrakNadpis {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 500px;
  margin: 0 auto 0 auto;
  font-size: 32px;
  font-family: SemiBold;
  color: #fff;
  background: center / contain no-repeat
    url("../assets/jazykovymentoringNadpis.png");
}
.nadpis {
  position: relative;
  top: 160px;
  font-size: 48px;
}
.mentoringNadpisPopis div,
.mentoringNadpisPopis_Mobile div {
  padding: 10px;
  color: white;
}
.mrakNadpis {
  position: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.mrakNadpis img {
  height: 500px;
}
.icons {
  display: flex;
  align-items: center;
  margin: 50px 0;
}
.icons img {
  width: 50px;
  margin: 10px 30px;
}
.mentoringPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1100px;
  margin-top: 360px;
  margin-left: auto;
  margin-right: auto;
}
.mentoringBlok {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  width: 100%;
  height: auto;
  margin: 100px 0;
}
.mentoringImgBox {
  height: 100%;
  flex: 0 0 50%;
  order: 1;
}
.mentoringImgBox Img {
  height: 100%;
  flex: 0 0 50%;
  float: left;
}
.mentoringBox {
  height: auto;
  flex: 1 1 50%;
  padding: 20px 10px;
  background: #fff;
  order: 2;
}
.mentoringPanely {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mentoringBoxLevy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 150px;
  background-color: var(--zluta);
  border-radius: 20px 0 0 20px;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    11px 1px 20px 5px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 10px 13px -7px #000000, 11px 1px 20px 5px rgba(0, 0, 0, 0.18);
}
.mentoringBoxStred {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 200px;
  background-color: var(--fialova);
  border-radius: 20px;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    20px 17px 15px -3px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 10px 13px -7px #000000,
    20px 17px 15px -3px rgba(0, 0, 0, 0.29);
  z-index: 1;
}
.mentoringBoxPravy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 150px;
  background-color: var(--zluta);
  border-radius: 0 20px 20px 0;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    11px 1px 20px 5px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 10px 13px -7px #000000, 11px 1px 20px 5px rgba(0, 0, 0, 0.18);
}
.mentoringPanely img {
  height: 60px;
  margin-bottom: 10px;
}
.mentoringPanely h4 {
  color: #fff;
}
.mrakNadpis_3 {
  position: relative;
  bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  color: #fff;
  background: center / 100% 90% no-repeat
    url("../assets/ucitbrno_bg_mrak01.png");
}
.mrakNadpis_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 100px;
  margin: 60px auto 60px auto;
  font-size: 32px;
  font-family: SemiBold;
  color: #fff;
  background: center / 100% 100% url("../assets/ucitbrno_bg_mrak01.png");
}
ul {
  max-width: 600px;
  padding: 20px;
}
li {
  list-style: disc;
  margin: 20px 0;
  text-align: left;
}

.pripadoveStudie div div {
  height: 900px;
  padding: 30px 20px;
  background-color: var(--zluta);
  border-radius: 20px;
  overflow-y: auto;
}
.pripadoveStudie h2 {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  color: #fff;
  background-color: var(--fialova);
  border-radius: 10px;
}

.mentoring .carouselBoxMentoring {
  width: 100%;
  /* background-color: var(--fialova); */
  /* background: center / 100% 100% no-repeat url("../assets/mrak-velky-fialovy.png"); */
}
.poptavka {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  margin: 30px 0 0 0;
  background: center / 100% 100% no-repeat
    url("../assets/mrak-velky-zluty-end.png");
}
.formPoptavka {
  max-width: 830px;
  height: auto;
  max-height: 850px;
  margin-top: 150px;
}
.textLabel {
  text-align: end;
  margin-top: 20px;
}
.formLabel {
  width: 130px;
  text-align: end;
  font-size: 21px;
  color: #fff;
}
.formInput Input {
  width: 520px;
  height: 45px;
  margin: 10px;
  padding: 10px;
  font-size: 21px;
  color: #000;
  background-color: #fff;
  border-radius: 15px;
}
.formInput textarea {
  width: 520px;
  min-height: 200px;
  max-height: 380px;
  margin: 10px;
  padding: 10px;
  font-size: 21px;
  color: #000;
  background-color: #fff;
  border-radius: 15px;
  overflow-y: auto;
}
.formButton {
  width: 100px;
  height: 35px;
  float: right;
  margin: 10px 10px 30px;
  font-family: SemiBold;
  color: var(--fialova);
  background-color: #fff;
  border-radius: 10px;
}
.v-divider {
  border-color: #fff !important;
}

@media screen and (max-width: 1260px) {
  .mentoringPanely {
    flex-direction: column;
    height: auto;
  }
  .mentoringPanely {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .mentoringBoxLevy {
    width: 260px;
    height: 240px;
    padding: 20px;
    border-radius: 20px 20px 0 0;
  }
  .mentoringBoxStred {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 340px;
    height: 300px;
    padding: 20px;
    color: #fff;
    border-radius: 20px;
  }
  .mentoringBoxPravy {
    width: 260px;
    height: 240px;
    padding: 20px;
    border-radius: 0 0 20px 20px;
  }
  .mentoringImgBox Img {
    height: 300px;
  }
  /* .mentoring .carouselBoxMentoring .carousel {
    background: center / 180% 100% no-repeat
      url("../assets/mrak-velky-zluty.png");
  } */
  .pripadoveStudie div div {
    height: auto;
  }
}
@media screen and (max-width: 992px) {
  .poptavka {
    background: center / 150% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
}
@media screen and (max-width: 860px) {
  .nadpisBox .nadpis {
    font-size: 38px;
    padding: 5px;
    transform: translate(0, -180px);
  }
  .mrakNadpis {
    display: flex;
    justify-content: center;
  }
  .mrakNadpis img {
    height: 300px;
  }
  .poptavka {
    background: center / 180% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
  .mrakNadpis {
    width: 500px;
  }
  .nadpis {
    top: 120px;
  }
}
@media screen and (max-width: 650px) {
  .poptavka {
    background: center / 200% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
}
@media screen and (max-width: 576px) {
  .nadpisBox .nadpis {
    font-size: 28px;
    padding: 5px;
    transform: translate(0, -190px);
  }
  .mrakNadpis img {
    height: 250px;
  }
  .mentoring .carouselBoxMentoring .carousel {
    /* background: center / 200% 100% no-repeat
      url("../assets/mrak-velky-zluty.png"); */
  }
  .poptavka {
    background: center / 250% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
  .mrakNadpis {
    width: 360px;
  }
  .nadpis {
    top: 90px;
    font-size: 28px;
  }
  .mrakNadpis_1 {
    width: 340px;
    height: 100px;
    margin: 60px auto 60px auto;
    font-size: 32px;
    font-family: SemiBold;
    color: #fff;
    background: center / 100% 100% url("../assets/ucitbrno_bg_mrak01.png");
  }
  .poptavka {
    width: 100vw;
  }
  .formInput Input {
    width: 95vw;
  }
  .formInput textarea {
    width: 95vw;
  }
}
</style>