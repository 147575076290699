<template>
  <div class="vop">
    <div class="nadpisBlock">
      <div class="mrakNadpis">
        <h1 class="nadpis">Všeobecné obchodní podmínky</h1>
      </div>
    </div>
    <div v-html="obsah[0]['vopText']" class="vop_body">
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Vop",
  components: {},
  created() {
    document.title = this.$route.meta.title;
    this.loadObsah();
  },
  data() {
    return {
      obsah: [],
    };
  },
  methods: {
    loadObsah() {
         axios.post(this.$myServerPath + "/loadObsah.php",{
        table: 'Vop'
      })
      .then((response) => {
        this.obsah = response.data;
        console.log( this.obsah);
      });
    },
  },
};
</script>

<style>
.vop {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 103px;
}
.vop .mrakNadpis {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 500px;
  margin: 0 auto 0 auto;
  font-size: 32px;
  font-family: SemiBold;
  color: #fff;
  background: center / contain no-repeat url("../assets/ucitbrno_bg_mrak01.png");
}
.vop .nadpis {
  position: relative;
  font-size: 48px;
}
.vop .vop_body {
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  margin-top: 650px;
  padding: 10px;
  background: #fff;
}
.vop .vop_body p{
  text-align: left;
  padding-left: 10px;
}
@media screen and (max-width: 860px) {
  .vop .mrakNadpis {
    width: 600px;
  }
  .vop .nadpis {
    font-size: 28px;
  }
}

@media screen and (max-width: 576px) {
  .vop .mrakNadpis {
    width: 360px;
  }
  .vop .nadpis {
    font-size: 18px;
  }
}
</style>