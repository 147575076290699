<template>
  <div class="footer">
    <div class="links">
      <router-link to="/">O NÁS </router-link>
      <router-link to="/firemni-vzdelavani">FIREMNÍ KURZY</router-link>
      <router-link to="/workshopy-seminare">WORKSHOPY A SEMINÁŘE </router-link>
      <router-link to="/jazykovy-mentoring">JAZYKOVÝ MENTORING </router-link>
      <router-link to="/lektorsky-tym">LEKTOŘI </router-link>
      <router-link to="/kontakty">KONTAKT</router-link>
    </div>
    <div class="adresa">
      <ul>
        <li>Učit Brno, s.r.o.</li>
        <li>IČ: 07369565</li>
        <li>DIČ: CZ07369565</li>
        <li>ADRESA: Mezírka 775/1</li>
        <li>602 00 Brno</li>
        <li>
      <router-link class="vop" to="/vop">všeobecné obchodní podmínky</router-link>
      </li>
      </ul>
    </div>


    <vue-cookie-accept-decline
    :ref="'myPanel1'"
    :elementId="'myPanel1'"
    :debug="false"
    :position="'bottom'"
    :type="'bar'"
    :disableDecline="false"
    :transitionName="'slideFromBottom'"
    :showPostponeButton="false"
    @status="cookieStatus"
    @clicked-accept="cookieClickedAccept"
    @clicked-decline="cookieClickedDecline">
 
    <!-- Optional -->
    <!-- <div slot="postponeContent">
        &times;
    </div> -->
 
    <!-- Optional -->
    <div slot="message">
        Máte rádi sušenky? My ano. Používáme cookies, abychom zajistili co možná nejsnadnější použití našich webových stránek a měli možnost je průběžně zlepšovat. <br> <a href="https://cookiesandyou.com/" target="_blank">Zjistit více...</a>
    </div>
 
    <!-- Optional -->
    <div slot="declineContent">
       Odmítnout
    </div>
 
    <!-- Optional -->
    <div slot="acceptContent">
        Příjmout
    </div>
</vue-cookie-accept-decline>


    <!-- <cookie-law theme="dark-lime"></cookie-law> -->
  </div>
</template>

<script>
// import CookieLaw from "vue-cookie-law";
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import '/node_modules/vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
export default {
  name: "Footer",
  // components: { CookieLaw },
  components: { VueCookieAcceptDecline },
};
</script>

<style scoped>
.footer {
  position: relative;
  width: 100%;
  height: 260px;
  background: center / cover no-repeat url("../assets/zapati.png");
  color: #fff;
}
.links {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 45px;
  left: 100px;
  text-align: left;
  /* font-family: SemiBold; */
}
.links a {
  font-size: 21px;
  color: #000;
}
a:hover {
  color: var(--fialova) !important;
  transition: 0.5s;
}
a.router-link-exact-active.router-link-active {
  color: var(--fialova) !important;
}
.adresa {
  position: absolute;
  top: 50px;
  right: 100px;
  text-align: left;
  font-size: 21px;
}
ul{
  list-style: none;
}
.vop{
  color: lightgray;
  font-size: 16px;
}
.vop:hover {
  color: gray !important;
}
a.vop.router-link-active {
  color: gray !important;
}
@media screen and (max-width: 1425px) {
  .adresa {
    right: 50px;
    text-align: left;
    font-size: 21px;
  }
}
@media screen and (max-width: 1290px) {
  .adresa {
    left: 550px;
  }
}
@media screen and (max-width: 930px) {
  .adresa {
    left: 450px;
  }
}
@media screen and (max-width: 720px) {
  .links {
  display: none;
}
.adresa {
    left: 50px;
  }
}
</style>