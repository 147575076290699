<template>
  <div>
    <div id="m" style="width: 100%; height: 450px; filter: grayscale(70%);"></div>
  </div>
</template>

<script>
export default {
  name: "MapaBlock",
  props: { currLang: String },
  mounted: function () {
    this.loadMap();
  },
  data() {
    return {};
  },
  methods: {
    loadMap() {
      //var center = SMap.Coords.fromWGS84(16.6040297, 49.20004);
      //var position = SMap.Coords.fromWGS84(16.6040297, 49.20004);
      var center = SMap.Coords.fromWGS84(16.6040808, 49.2009683);
      var position = SMap.Coords.fromWGS84(16.6040808, 49.2009683);
      var m = new SMap(JAK.gel("m"), center, 14);
      m.addDefaultLayer(SMap.DEF_BASE).enable();
      m.addDefaultControls();

      var layer = new SMap.Layer.Marker();
      m.addLayer(layer);
      layer.enable();

      var options = {};
      var marker = new SMap.Marker(position, "myMarker", options);
      layer.addMarker(marker);

      /* znackova vrstva pro ikonky bodu zajmu; poiToolTip - zapneme title jako nazev nad POI */
      var layer = new SMap.Layer.Marker(undefined, {
        poiTooltip: true,
      });
      m.addLayer(layer).enable();

      /* dataProvider zastiti komunikaci se servery */
      var dataProvider = m.createDefaultDataProvider();
      dataProvider.setOwner(m);
      dataProvider.addLayer(layer);
      dataProvider.setMapSet(SMap.MAPSET_BASE);
      dataProvider.enable();
    },
  },
};
</script>

<style>
</style>