<template>
  <div class="carouselKurzy">
    <carousel
      :perPageCustom="[
        [220, 1],
        [700, 2],
        [1030, 3],
        [1380, 4],
        [1700, 5],
      ]"
      :navigationEnabled="true"
    >
      <slide v-for="(lektor, i) in lektori" :key="i" style="margin-left: auto; margin-right: auto;">
        <img
          :src="`${$myServerPath}/LectorsPics/${lektor.img_file_name}`"
           :alt="`obrázek lektora ${lektor.jmeno}`" @error="replaceByDefaultImg"
        />
        <div class="mrakNadpis_3" @click="showLektorDetail(lektor.id)">
          <h4 >{{ lektor.jmeno }}</h4>
        </div>
      </slide>
    </carousel>

    <!-- <v-dialog
      v-model="dialog"
      max-width="80%"
    >
      <v-card
    class="mx-auto"
  >
    <v-img
      class="white--text align-end "
      width="200px"
      :src="`${$myServerPath}/LectorsPics/${lektori[index].img_file_name}`" alt=""
    >
      <v-card-title class="center">{{lektori[index].jmeno + " " + lektori[index].prijmeni }}</v-card-title>
    </v-img>

    <v-card-subtitle class="pb-0">
     {{lektori[index].lektor_text}}
    </v-card-subtitle>

    <v-card-text class="text--primary">
      <div>{{lektori[index].oblb_jidlo}}</div>
      <div>{{lektori[index].oblb_film}}</div>
      <div>{{lektori[index].oblb_kniha}}</div>
      <div>{{lektori[index].oblb_hudba}}</div>
      <div>{{lektori[index].oblb_sporty}}</div>
      <div>{{lektori[index].vysnene_povolani}}</div>
      <div>{{lektori[index].co_volny_cas}}</div>
      <div>{{lektori[index].jaky_idealni_lektor}}</div>
      <div>{{lektori[index].co_bavi_na_uceni}}</div>
      <div>{{lektori[index].proc_ucit_brno}}</div>

    </v-card-text>

    <v-card-actions>
      <v-btn
        color="orange"
        text
      >
        Rezervovat
      </v-btn>
    </v-card-actions>
  </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import axios from "axios";
import img from "@/assets/userImgBlank.png";
export default {
  props: ['workshopID'],
  created() {
    this.getLektorList();
  },
  mounted() {},
  computed: {},
  data() {
    return {
      lektori: [],
      currentID: "",
      index: 0,
      dialog: false,
      modalShow: Boolean,
    };
  },

  methods: {
    getLektorList() {
      axios
        .post(this.$myServerPath + "/getWorkshopLektors.php", {
         workshopID: this.workshopID 
        })
        .then((response) => {
          this.lektori = response.data;
        });
    },
    showLektorDetail(id) {
      this.$router.push({ name: "LektorDetail", query: { lektorID: id } });
    },
    replaceByDefaultImg(e) {
      e.target.src = img;
      this.lideImgBtnDisabled = true;
    },
  },
};
</script>

<style>
.carouselKurzy {
  height: 660px;
}
.carouselKurzy img {
  width: 220px;
}
.VueCarousel{
  top: 150px;
}
.VueCarousel-slide {
  height: 340px;
  padding: 30px 0;
}
.carouselKurzy .mrakNadpis_3 {
  position: relative;
  bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 340px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  color: #fff;
  background: center / 100% 90% no-repeat
    url("../assets/ucitbrno_bg_mrak01.png");
    cursor: pointer;
}
.VueCarousel-navigation-prev {
  top: 30% !important;
  left: 20px !important;
  transform: none !important;
  font-size: 0;
  background-image: url("../assets/arrowLeft.png");
  width: 20px !important;
}
.VueCarousel-navigation-next {
  top: 30% !important;
  right: 20px !important;
  transform: none !important;
  font-size: 0;
  background-image: url("../assets/arrowRight.png");
  width: 20px !important;
}
.VueCarousel-navigation-button:focus {
  outline: none !important;
}

</style>