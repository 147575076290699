<template>
  <div class="carouselReferenceMentoring">
    <carousel :perPage="1" :navigationEnabled="true">
      <slide v-for="(refer, i) in reference" :key="i">
        <div v-html="refer.referenceText"></div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import axios from "axios";
export default {
  created() {
    this.loadReference();
  },
  mounted() {},
  computed: {},
  data() {
    return {
      reference: [],
      currentID: "",
      index: 0,
      dialog: false,
      modalShow: Boolean,
    };
  },

  methods: {
    loadReference() {
      axios
        .post(this.$myServerPath + "/loadObsah.php", {
          table: "JazykovyMentoringReference",
        })
        .then((response) => {
          this.reference = response.data;
        });
    },
  },
};
</script>

<style>
.carouselReferenceMentoring {
  /* background-color: var(--fialova); */
}
.carouselReferenceMentoring p {
  max-width: 640px;
  padding: 0 30px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}
.carouselReferenceMentoring .VueCarousel {
  top: 0;
  background-color: var(--fialova);
}
.VueCarousel-pagination{
 
}
.carouselReferenceMentoring .VueCarousel-slide {
  height: auto;
  padding: 30px 10px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.carouselReferenceMentoring .VueCarousel-navigation-prev {
  top: 30% !important;
  left: 20px !important;
  transform: none !important;
  font-size: 0;
  background-image: url("../assets/arrowLeft.png");
  width: 20px !important;
}
.carouselReferenceMentoring .VueCarousel-navigation-next {
  top: 30% !important;
  right: 20px !important;
  transform: none !important;
  font-size: 0;
  background-image: url("../assets/arrowRight.png");
  width: 20px !important;
}
.carouselReferenceMentoring .VueCarousel-navigation-button:focus {
  outline: none !important;
}
@media (max-width: 768px){
}

</style>