<template>
  <div class="strana404">
    <div class="nadpisBlock">
      <div class="mrakNadpis">
        <h1 class="nadpis">Tato stránka bohužel neexistuje</h1>
      </div>
    </div>
    <div class="strana404_body">

      <img src="@/assets/404.jpg" alt="">
     
    </div>
  </div>
</template>

<script>
export default {
  name: "strana404",
  components: {},
  created() {
    document.title = this.$route.meta.title;
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.strana404 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 103px;
}
.strana404 .mrakNadpis {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 500px;
  margin: 0 auto 0 auto;
  font-size: 32px;
  font-family: SemiBold;
  color: #fff;
  background: center / contain no-repeat url("../assets/ucitbrno_bg_mrak01.png");
}
.strana404 .nadpis {
  position: relative;
  font-size: 48px;
}
.strana404 .strana404_body {
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 600px;
  background: #fff;
}
.strana404 .strana404_body img{
  max-width: 100vw;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 860px) {
  .strana404 .mrakNadpis {
    width: 600px;
  }
  .strana404 .nadpis {
    font-size: 28px;
  }
}

@media screen and (max-width: 576px) {
  .strana404 .mrakNadpis {
    width: 360px;
  }
  .strana404 .nadpis {
    font-size: 18px;
  }
}
</style>