<template>
  <div class="lektori">
    <div class="nadpisBlock">
      <div class="mrakNadpis">
        <!-- <img src="@/assets/mrak-nadpis.png" alt="background nadpis" /> -->
        <h1 class="nadpis">
          LEKTORSKÝ TÝM
        </h1>
      </div>
    </div>

    <div class="uvod">
      <div v-html="obsah[0].uvodText"></div>

      <v-row no-gutters class="icons">
        <v-col cols="12" md="6" lg="3" align="center" justify="start">
          <div class="iconsImgBox">
            <img
              :src="`${$myServerPath}/LectorsIcons/${obsah[0]['point1IconFile']}`"
              alt="obrázek k bodu 1"
            />
          </div>
          <h3 v-html="obsah[0].point1"></h3>
        </v-col>
        <v-col cols="12" md="6" lg="3" align="center" justify="start">
          <div class="iconsImgBox">
            <img
              :src="`${$myServerPath}/LectorsIcons/${obsah[0]['point2IconFile']}`"
              alt="obrázek k bodu 2"
            />
          </div>
          <h3 v-html="obsah[0].point2"></h3>
        </v-col>
        <v-col cols="12" md="6" lg="3" align="center" justify="start">
          <div class="iconsImgBox">
            <img
              :src="`${$myServerPath}/LectorsIcons/${obsah[0]['point3IconFile']}`"
              alt="obrázek k bodu 3"
            />
          </div>
          <h3 v-html="obsah[0].point3"></h3>
        </v-col>
        <v-col cols="12" md="6" lg="3" align="center" justify="start">
          <div class="iconsImgBox">
            <img
              :src="`${$myServerPath}/LectorsIcons/${obsah[0]['point4IconFile']}`"
              alt="obrázek k bodu 4"
            />
          </div>
          <h3 v-html="obsah[0].point4"></h3>
        </v-col>
      </v-row>

      <div class="mrakNadpis01">
        <div>SEZNAMTE SE</div>
      </div>

      <div class="lektors">
        <div v-for="(lektor, i) in lektori" :key="i">
          <div class="lektorBox">
            <img
              :src="`${$myServerPath}/LectorsPics/${lektor.img_file_name}`"
              :alt="`obrázek lektora ${lektor['jmeno']}`"
              @error="replaceByDefaultImg"
            />
            <div class="mrakNadpis_3" @click="showLektorDetail(lektor.id)">
              <h4>{{ lektor.jmeno }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="stanSeLektoremBlock">
      <div class="stanSeLektoremLeft">
        <img
          src="../assets/vojkuvkovyNabor.png"
          alt="obrázek vojkůvkova obličeje"
        />
        <!-- <div class="mrakNadpis_4">
          <h4>STAŇ SE LEKTOREM</h4>
        </div> -->
      </div>
      <div class="stanSeLektoremRight">
        <div class="stanSeLektoremRightBox">
          <div v-html="obsah[0].pojdUcitText"></div>
          <button @click="toKontakt()">Ozvi se nám</button>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from "axios";
import img from "@/assets/userImgBlank.png";
export default {
  name: "Lektori",
  components: {},
  created() {
    this.getLektorList();
    this.loadObsah();
    document.title = this.$route.meta.title;
  },
  data() {
    return {
      lektori: [],
      obsah: [],
      currentID: "",
      index: 0,
    };
  },

  methods: {
    getLektorList() {
      axios
        .post(this.$myServerPath + "/getLektors.php", {})
        .then((response) => {
          this.lektori = response.data;
        });
    },
    loadObsah() {
      axios
        .post(this.$myServerPath + "/loadObsah.php", {
          table: "LektoriPage",
        })
        .then((response) => {
          this.obsah = response.data;
        });
    },
    showLektorDetail(id) {
      var jmeno = this.lektori.filter((x)=>x.id == id).map(x => x.jmeno);
      var prijmeni = this.lektori.filter((x)=>x.id == id).map(x => x.prijmeni);
      var name = jmeno + "-" + prijmeni;
      var nameFinal = this.toBasicLatin(name)
      this.$router.push({ path: "lektorsky-tym/" + nameFinal + "/" + id});
    },
    toKontakt() {
      this.$router.push("/kontakty");
    },
    replaceByDefaultImg(e) {
      e.target.src = img;
      this.lideImgBtnDisabled = true;
    },
        toBasicLatin(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036F]/g, "");
    },
  },
};
</script>

<style scoped>
.lektori {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 220px;
}
.nadpisBox {
  position: relative;
  height: 100%;
  left: 0;
}
.nadpisBox .nadpis {
  position: absolute;
  top: 180px;
  left: 50px;
  text-align: left;
  font-size: 48px;
  line-height: 1;
  padding: 5px;
  color: #fff;
  z-index: 2;
}
.mrakNadpis {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 500px;
  margin: 0 auto 0 auto;
  font-size: 32px;
  font-family: SemiBold;
  color: #fff;
  background: center / contain no-repeat url("../assets/lektoriNadpis.png");
}
.nadpis {
  position: relative;
  top: 150px;
  font-size: 48px;
}
.mrakNadpis01 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 100px;
  margin: 60px auto 60px auto;
  text-align: center;
  font-size: 32px;
  font-family: SemiBold;
  color: #fff;
  background: center / contain no-repeat url("../assets/ucitbrno_bg_mrak01.png");
}
.mrakNadpis img {
  position: absolute;
  height: 100%;
  left: 0;
  z-index: 1;
}
.mrakNadpisImg Img {
  position: absolute;
  top: 40px;
  right: 0;
  height: 400px;
  z-index: 1;
}
.uvod {
  margin-top: 450px;
  max-width: 1100px;
  font-family: SemiBold;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}
.icons {
  display: flex;
  align-items: center;
  margin: 50px 0 100px 0;
}
.iconsImgBox {
  display: flex;
  justify-content: center;
  align-items: start;
  flex: 0 0 100px;
}
.icons img {
  height: 130px;
  margin: 10px 30px;
}
.icons h3 {
  flex-shrink: 1;
  justify-self: start;
}
.lektors {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.lektors h4 {
}
.lektorBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 260px;
  margin-left: auto;
  margin-right: auto;
}
.lektorBox img {
  width: 180px;
  height: 180px;
  object-fit: contain;
}
.mrakNadpis_3 {
  position: relative;
  bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  color: #fff;
  background: center / 100% 90% no-repeat
    url("../assets/ucitbrno_bg_mrak01.png");
  cursor: pointer;
}
.stanSeLektoremBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 750px;
  width: 100%;
  margin: 30px 0;
  background: center / 100% 100% no-repeat
    url("../assets/mrak-velky-fialovy.png");
}
.stanSeLektoremLeft {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  flex: 1 1 50%;
}
.stanSeLektoremLeft Img {
  height: 450px;
  /* width: 60%; */
  object-fit: contain;
}
.mrakNadpis_4 {
  position: relative;
  bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  background: center / 100% 90% no-repeat
    url("../assets/ucitbrno_bg_mrak01_white.png");
}
.stanSeLektoremRightBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 50%;
}
.stanSeLektoremRight {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  flex: 1 1 50%;
}
.stanSeLektoremRight div {
  width: 460px;
  /* text-align: justify; */
  color: #fff;
  margin: 0;
  padding: 0 10px 10px 10px;
}
.stanSeLektoremBlock button {
  display: flex;
  justify-content: center;
  width: 230px;
  height: 55px;
  margin: 0;
  font-size: 32px;
  font-family: SemiBold;
  color: var(--fialova);
  background-color: #fff;
  border-radius: 10px;
}
button:hover {
  border: 1px solid var(--fialova);
  transition: 0.5s;
}
@media screen and (max-width: 1200px) {
  .lectors {
    grid-template-columns: repeat(3, 1fr);
  }
  .stanSeLektoremBlock {
    flex-direction: column;
    height: 1000px;
    width: 100%;
    margin: 30px 0;
    background: center / 180% 100% no-repeat
      url("../assets/mrak-velky-fialovy.png");
  }
  .stanSeLektoremLeft {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    flex: 1 1 30%;
  }
  .stanSeLektoremLeft Img {
  width: 350px;
  height: 250px;
}
  .stanSeLektoremRightBox {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    flex: 1 1 70%;
  }
}
@media screen and (max-width: 992px) {
  .lectors {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 860px) {
  .nadpisBox {
    overflow: hidden;
  }
  .uvod {
    padding: 5px;
  }
  .uvod div {
    width: 300px;
    padding: 0;
  }
  .lectors {
    grid-template-columns: repeat(1, 1fr);
  }
  .lektorBox {
    width: 340px;
  }
  .mrakNadpis {
    width: 500px;
  }
  .nadpis {
    top: 120px;
  }
}
@media screen and (max-width: 520px) {
  .stanSeLektoremRight div {
    width: 320px;
  }
  .mrakNadpis {
    width: 360px;
  }
  .nadpis {
    top: 85px;
    font-size: 38px;
  }
}
</style>