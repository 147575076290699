<template>
  <div class="carouselReferenceFiremni">
    <carousel :perPage="1" :navigationEnabled="true">
      <slide v-for="(refer, i) in reference" :key="i">
        <div v-html="refer.referenceText"></div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import axios from "axios";
export default {
  created() {
    this.loadReference();
  },
  mounted() {},
  computed: {},
  data() {
    return {
      reference: [],
      currentID: "",
      index: 0,
      dialog: false,
      modalShow: Boolean,
    };
  },

  methods: {
    loadReference() {
      axios
        .post(this.$myServerPath + "/loadObsah.php", {
          table: "FiremniKurzyReference",
        })
        .then((response) => {
          this.reference = response.data;
        });
    },
  },
};
</script>

<style>
.carouselReferenceFiremni {
  /* height: 550px; */
}
.carouselReferenceFiremni p {
  max-width: 640px;
  padding: 30px 30px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}
.carouselReferenceFiremni .VueCarousel {
  top: 0;
  background-color: var(--fialova);

}
.carouselReferenceFiremni .VueCarousel-slide {
  height: auto;
  padding:  0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.carouselReferenceFiremni .VueCarousel-navigation-prev {
  top: 30% !important;
  left: 20px !important;
  transform: none !important;
  font-size: 0;
  background-image: url("../assets/arrowLeft.png");
  width: 20px !important;
}
.carouselReferenceFiremni .VueCarousel-navigation-next {
  top: 30% !important;
  right: 20px !important;
  transform: none !important;
  font-size: 0;
  background-image: url("../assets/arrowRight.png");
  width: 20px !important;
}
.carouselReferenceFiremni .VueCarousel-navigation-button:focus {
  outline: none !important;
}
.carouselReferenceFiremni a {
  color: #c6c6c6 !important;
}
.carouselReferenceFiremni a:hover {
  color: #a9a9a9 !important;
}
</style>