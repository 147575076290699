<template>
  <div class="workshop">
    <div class="nadpisBlock">
      <div class="mrakNadpis">
        <!-- <img src="@/assets/mrak-nadpis.png" alt="background nadpisu" /> -->
        <h1 class="nadpis">WORKSHOPY - SEMINÁŘE</h1>
      </div>
    </div>

    <div class="workshopPage">
      <div class="uvod">
        <div v-html="obsah[0].uvodText"></div>
      </div>

      <div v-for="(workshop, i) in workshopy" :key="i" class="workshopBlok">
        <div :class="[isEven(i) ? 'order1' : 'order2', 'workshopImgBox']">
          <img
            :src="`${$myServerPath}/WorkshopyIcons/${workshop['bannerIcon4']}`"
            alt="obrázek workshopu"
          />
        </div>

        <div :class="[isEven(i) ? 'box-order2' : 'box-order1', 'workshopBox']">
          <h2 v-html="workshop['nazev']"></h2>
          <div class="workshopPanely">
            <div class="workshopBoxLevy">
              <img
                :src="`${$myServerPath}/WorkshopyIcons/${workshop['bannerIcon1']}`"
                alt="ikona banneru 1"
              />
              <h4 v-html="workshop['bannerText1']"></h4>
            </div>
            <div class="workshopBoxStred">
              <img
                :src="`${$myServerPath}/WorkshopyIcons/${workshop['bannerIcon2']}`"
                alt="ikona banneru 2"
                style="height: 80px"
              />
              <h4 v-html="workshop['bannerText2']"></h4>
            </div>
            <div class="workshopBoxPravy">
              <img
                :src="`${$myServerPath}/WorkshopyIcons/${workshop['bannerIcon3']}`"
                alt="ikona banneru 3"
              />
              <h4 v-html="workshop['bannerText3']"></h4>
            </div>
          </div>
          <button @click="showWorkshopDetail(workshop.id)">zjistit více</button>
        </div>
      </div>
    </div>

    <div class="mrakNadpis_1">
      <div>JAK TO U NÁS CHODÍ</div>
    </div>

    <div v-if="!onMobile" class="postup">
      <div class="postupBox">
        <img src="@/assets/dot1.jpg" alt="dot" />
        <img class="sipkaHorni" src="@/assets/sipkaHorni.png" alt="šipka" />
        <p v-html="obsah[0].dot1"></p>
      </div>
      <div class="postupBox">
        <img src="@/assets/dot2.jpg" alt="dot" />
        <img class="sipkaSpodni" src="@/assets/sipkaSpodni.png" alt="šipka" />
        <p v-html="obsah[0].dot2"></p>
      </div>
      <div class="postupBox">
        <img src="@/assets/dot3.jpg" alt="dot" />
        <img class="sipkaHorni" src="@/assets/sipkaHorni.png" alt="šipka" />
        <p v-html="obsah[0].dot3"></p>
      </div>
      <div class="postupBox">
        <img src="@/assets/dot4.jpg" alt="dot" />
        <img class="sipkaSpodni" src="@/assets/sipkaSpodni.png" alt="šipka" />
        <p v-html="obsah[0].dot4"></p>
      </div>
      <div class="postupBox">
        <img src="@/assets/dot5.jpg" alt="dot" />
        <img
          class="sipkaHorni"
          src="@/assets/sipkaHorni.png"
          alt="šipka"
          style="visibility: hidden"
        />
        <p v-html="obsah[0].dot5"></p>
      </div>
    </div>

    <div v-if="onMobile" class="postupMobile">
      <div class="postupBoxMobile">
        <img src="@/assets/dot1.jpg" alt="dot" />
        <img
          class="sipkaHorniMobile"
          src="@/assets/sipkaHorni.png"
          alt="šipka"
        />
        <p v-html="obsah[0].dot1"></p>
      </div>
      <div class="postupBoxMobile">
        <img src="@/assets/dot2.jpg" alt="dot" />
        <img
          class="sipkaSpodniMobile"
          src="@/assets/sipkaSpodni.png"
          alt="šipka"
        />
        <p v-html="obsah[0].dot2"></p>
      </div>
      <div class="postupBoxMobile">
        <img src="@/assets/dot3.jpg" alt="dot" />
        <img
          class="sipkaHorniMobile"
          src="@/assets/sipkaHorni.png"
          alt="šipka"
        />
        <p v-html="obsah[0].dot3"></p>
      </div>
      <div class="postupBoxMobile">
        <img src="@/assets/dot4.jpg" alt="dot" />
        <img
          class="sipkaSpodniMobile"
          src="@/assets/sipkaSpodni.png"
          alt="šipka"
        />
        <p v-html="obsah[0].dot4"></p>
      </div>
      <div class="postupBoxMobile">
        <img src="@/assets/dot5.jpg" alt="dot" />
        <img
          class="sipkaHorniMobile"
          src="@/assets/sipkaHorni.png"
          alt="šipka"
          style="visibility: hidden"
        />
        <p v-html="obsah[0].dot5"></p>
      </div>
    </div>

    <div class="mrakNadpis_1">
      <div>POPTÁVKA</div>
    </div>

    <div class="poptavka">
      <div class="formPoptavka">
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">JMÉNO</div>
          <div class="formInput">
            <input v-model="jmeno" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">FIRMA</div>
          <div class="formInput">
            <input v-model="firma" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">EMAIL</div>
          <div class="formInput">
            <input v-model="email" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">TELEFON</div>
          <div class="formInput">
            <input v-model="phone" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="start" justify-md="end">
          <div class="formLabel textLabel">ZPRÁVA</div>
          <div class="formInput">
            <textarea v-model="poznamka" type="text"></textarea>
          </div>
        </v-row>
        <button v-on:click="odeslat" class="formButton">odeslat</button>
      </div>
    </div>
    <v-dialog v-model="dialog" width="360">
      <v-card>
        <v-card-text class="card_text">
          {{ dialogText }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Workshopy",
  components: {},
  created() {
    document.title = this.$route.meta.title;
    this.loadObsah();
    this.getWorkshopyList();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    onMobile() {
      if (this.windowWidth < 992) {
        return true;
      } else return false;
    },
  },
  data() {
    return {
      obsah: [],
      workshopy: [],
      windowWidth: window.innerWidth,

      firma: "",
      jmeno: "",
      email: "",
      phone: "",
      poznamka: "",

      dialog: false,
      dialogText: "",
    };
  },
  methods: {
    getWorkshopyList() {
      axios
        .post(this.$myServerPath + "/getWorkshops.php", {})
        .then((response) => {
          this.workshopy = response.data;
        });
    },
    loadObsah() {
      axios
        .post(this.$myServerPath + "/loadObsah.php", {
          table: "WorkshopyPage",
        })
        .then((response) => {
          this.obsah = response.data;
        });
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    showWorkshopDetail(id) {
      var str = this.workshopy.filter((x) => x.id == id).map((x) => x.nazev);
      var div = document.createElement("div");
      div.innerHTML = str;
      var nazev = div.textContent || div.innerText || "";
      var finalNazev = nazev.replaceAll(" ", "-");
      var nazevBezDiakritiky = this.toBasicLatin(finalNazev);
      this.$router.push({
        path: "workshopy-seminare/" + nazevBezDiakritiky + "/" + id,
      });
      // this.$router.push({ name: 'Workshopy-Seminare', params: { workshopID: id } });
    },
    isEven(i) {
      if (i % 2 == 0) {
        return true;
      } else {
        return false;
      }
    },
    odeslat() {
      if (this.jmeno == "" || this.email == "") {
        this.dialogText =
          "Prosím vyplňte alespoň jméno kontaktní osoby a e-mail.";
        this.dialog = true;
      } else
        axios
          .post(this.$myServerPath + "/sendMail.php", {
            firma: this.firma,
            jmeno: this.jmeno,
            email: this.email,
            phone: this.phone,
            poznamka: this.poznamka,
          })
          .then(() => {
            this.dialogText = "Formulář byl odeslán.";
            this.dialog = true;
            this.firma = "";
            this.jmeno = "";
            this.email = "";
            this.phone = "";
            this.poznamka = "";
          });
    },
    toBasicLatin(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036F]/g, "");
    },
  },
};
</script>

<style scoped>
.workshop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 220px;
}
.nadpisBox {
  position: relative;
  height: 100%;
  left: 0;
}
.nadpisBox .nadpis {
  position: absolute;
  top: 180px;
  left: 50px;
  text-align: left;
  font-size: 48px;
  line-height: 1;
  padding: 5px;
  color: #fff;
  z-index: 2;
}
.mrakNadpis {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 500px;
  margin: 0 auto 0 auto;
  font-size: 32px;
  font-family: SemiBold;
  color: #fff;
  background: center / contain no-repeat url("../assets/workshopyNadpis.png");
}
.nadpis {
  position: relative;
  top: 150px;
  font-size: 48px;
}
.mrakNadpis img {
  position: absolute;
  height: 100%;
  left: 0;
  z-index: 1;
}
.uvod {
  margin-top: 50px;
  max-width: 1100px;
  font-family: SemiBold;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}
.icons {
  display: flex;
  align-items: center;
  margin: 50px 0;
}
.icons img {
  width: 50px;
  margin: 10px 30px;
}
.workshopPage {
  margin-top: 380px;
  width: 100%;
}
.workshopBlok {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  width: 100%;
  height: 450px;
  margin: 100px 0;
}
.workshopImgBox {
  height: 100%;
  flex: 1 1 50%;
}
.workshopImgBox Img {
  height: 100%;
  flex: 1 1 50%;
}
.workshopBox {
  height: 100%;
  flex: 1 1 50%;
  padding: 50px 10px;
  background: #fff;
}
.workshopBox h2 {
  font-size: 56px;
  margin-bottom: 30px;
  color: var(--fialova);
}
.workshopPanely {
  display: flex;
  align-items: center;
  justify-content: center;
}
.workshopBoxLevy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 150px;
  background-color: var(--zluta);
  border-radius: 20px 0 0 20px;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    11px 1px 20px 5px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 10px 13px -7px #000000, 11px 1px 20px 5px rgba(0, 0, 0, 0.18);
}
.workshopBoxStred {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 200px;
  background-color: var(--fialova);
  border-radius: 20px;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    20px 17px 15px -3px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 10px 13px -7px #000000,
    20px 17px 15px -3px rgba(0, 0, 0, 0.29);
  z-index: 1;
}
.workshopBoxPravy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 150px;
  background-color: var(--zluta);
  border-radius: 0 20px 20px 0;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    11px 1px 20px 5px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 10px 13px -7px #000000, 11px 1px 20px 5px rgba(0, 0, 0, 0.18);
}
.workshopPanely img {
  height: 60px;
  margin-bottom: 10px;
}
.workshopPanely h4 {
  color: #fff;
}
.mrakNadpis_3 {
  position: relative;
  bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  color: #fff;
  background: center / 100% 90% no-repeat
    url("../assets/ucitbrno_bg_mrak01.png");
}
.stanSeLektoremBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 30px 0;
  background: center / 100% 100% no-repeat
    url("../assets/mrak-velky-fialovy.png");
}
.mrakNadpis_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 100px;
  margin: 60px auto 60px auto;
  font-size: 32px;
  font-family: SemiBold;
  color: #fff;
  background: center / 100% 100% url("../assets/ucitbrno_bg_mrak01.png");
}
.postup {
  display: flex;
  justify-content: center;
  align-items: start;
  max-width: 1200px;
  height: auto;
  margin: 100px auto 100px auto;
}
.postupBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
}
.postupBox p {
  margin: 20px 10px;
}
.sipkaHorni {
  width: 100px;
  position: relative;
  top: -120px;
  left: 80px;
}
.sipkaSpodni {
  width: 100px;
  position: relative;
  top: 0px;
  left: 80px;
}
.postupMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 0 auto;
}
.postupBoxMobile {
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
}
.postupBoxMobile p {
  margin: 10px;
}
.sipkaHorniMobile {
  width: 100px;
  position: relative;
  top: 60px;
  left: 150px;
  transform: rotate(90deg);
}
.sipkaSpodniMobile {
  width: 100px;
  position: relative;
  top: 60px;
  left: -160px;
  transform: rotate(90deg);
}
.poptavka {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  margin: 30px 0 0 0;
  background: center / 100% 100% no-repeat
    url("../assets/mrak-velky-fialovy-end.png");
}
.formPoptavka {
  max-width: 830px;
  height: auto;
  max-height: 850px;
  margin-top: 150px;
}
.textLabel {
  text-align: end;
  margin-top: 20px;
}
.formLabel {
  text-align: end;
  width: 130px;
  font-size: 21px;
  color: #fff;
}
.formInput Input {
  width: 520px;
  height: 45px;
  margin: 10px;
  padding: 10px;
  font-size: 21px;
  color: #000;
  background-color: #fff;
  border-radius: 15px;
}
.formInput textarea {
  width: 520px;
  min-height: 200px;
  max-height: 380px;
  margin: 10px;
  padding: 10px;
  font-size: 21px;
  color: #000;
  background-color: #fff;
  border-radius: 15px;
  overflow-y: auto;
}
.formButton {
  width: 100px;
  height: 35px;
  float: right;
  margin: 10px 10px 30px;
  font-family: SemiBold;
  color: var(--fialova);
  background-color: #fff;
  border-radius: 10px;
}
.order1 {
  order: 1;
}
.order2 {
  order: 2;
}
.box-order1 {
  order: 1;
}
.box-order2 {
  order: 2;
}
@media screen and (max-width: 1460px) {
  .workshopBlok {
    flex-direction: column;
    height: auto;
  }
  .workshopImgBox Img {
    height: 300px;
  }
  .order1 {
    order: 2;
  }
  .order2 {
    order: 1;
  }
  .workshopImgBox Img {
    max-width: 98vw;
    height: auto;
  }
  .workshopBox {
    padding: 10px;
  }
}
@media screen and (max-width: 992px) {
  .poptavka {
    background: center / 150% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
}
@media screen and (max-width: 860px) {
  .nadpisBox {
    overflow: hidden;
  }
  .mrakNadpis_1 {
    width: 350px;
    font-size: 28px;
  }
  .poptavka {
    background: center / 180% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
  .mrakNadpis {
    width: 500px;
  }
  .nadpis {
    top: 120px;
  }
}
@media screen and (max-width: 650px) {
  .poptavka {
    background: center / 200% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
}
@media screen and (max-width: 576px) {
  .mentoring .carouselBox .carousel {
    background: center / 200% 100% no-repeat
      url("../assets/mrak-velky-zluty.png");
  }
  .poptavka {
    background: center / 250% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }

  .poptavka {
    width: 100vw;
  }
  .formInput Input {
    width: 95vw;
  }
  .formInput textarea {
    width: 95vw;
  }
  .mrakNadpis {
    width: 360px;
  }
  .nadpis {
    top: 90px;
    font-size: 32px;
  }
}
</style>


