<template>
  <v-app>
    <div id="app">
      <NavBar />
      <router-view></router-view>
      <Footer />
    </div>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "app",
  components: {
    NavBar,
    Footer,
  },
  created() {
    // this.$router.push("/home");
  },
};
</script>

<style>
@font-face {
  font-family: "Medium";
  src: local("Fonts"),
    url(./Fonts/BarlowCondensed-Medium.ttf) format("truetype"); 
}
@font-face {
  font-family: "SemiBold";
  src: local("Fonts"),
    url(./Fonts/BarlowCondensed-SemiBold.ttf) format("truetype"); 
}
@font-face {
  font-family: "Regular";
  src: local("Fonts"),
    url(./Fonts/BarlowCondensed-Regular.ttf) format("truetype"); 
}

html {
  box-sizing: border-box;
  /* min-width: 900px; */
}
*,
::after,
::before {
  box-sizing: inherit;
}
body {
  text-rendering: optimizeLegibility;
  margin: 0;
  padding: 0;
  font-size: 21px;
}

#app {
  font-family: Regular, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
a {
  text-decoration: none;
}
ul{
  /* list-style: none; */
}
.nadpisBlock{
  position: absolute;
  height: 500px;
  width: 100%;
  top: 100px;
  background: center / cover url("assets/bg-nadpis.png");
  /* border-bottom: 20px solid var(--fialovaMenu) ; */
}
.v-application ul, .v-application ol{
  padding: 0;
}
button {
  width: 120px;
  height: 45px;
  margin: 30px 0;
  font-family: SemiBold;
  font-size: 18px;
  color: #fff;
  background-color: var(--fialova);
  border-radius: 10px;
}
button:hover {
  color: var(--fialova);
  background-color: #fff;
  border: 1px solid var(--fialova);
  transition: 0.5s;
}
.growColumn{
  flex-grow: 1;
}
.font_Barlow{
  font-family: 'Barlow', sans-serif;
}
:root {
  --fialova:#7d3b96;
  --fialovaMenu: #371B44;
  --zluta:#efbb1e;
  --cerna:#000;
}
#app > div.v-dialog__content.v-dialog__content--active > div > div > div {
  padding: 20px;
}
#app > div.v-dialog__content.v-dialog__content--active > div > div > div {
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
}
</style>
