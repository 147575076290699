<template>
  <div class="lektorDetail">
    <div class="nadpisBlock">
      <img
        :src="`${$myServerPath}/LectorsPics/${lektor[0]['img_file_name']}`"
        :alt="`obrázek lektora ${lektor[0].jmeno}`"
        @error="replaceByDefaultImg"
      />
      <div class="mrakNadpis_1">
        <div>{{ lektor[0].jmeno }} {{ lektor[0].prijmeni }}</div>
      </div>
    </div>

    <div class="lektorDetailPage">
      <div class="mrakNadpis_1a">
        <div>{{ lektor[0].pozice }}</div>
      </div>
      <div v-if="!onMobile" class="lektorDetailBlok">
        <div class="koleckoBox">
          <img class="kolecko" src="@/assets/kolecko.png" alt="kolecko" />
          <img
            class="lektorImg"
            :src="`${$myServerPath}/LectorsPics/LectorsPicsOrig/${lektor[0]['img_file_name_orig']}`"
            :alt="`obrázek lektora ${lektor[0].jmeno}`"
          />
          <div class="vlastnost vlastnost1">
            <h4>Oblíbené jídlo</h4>
            <p>{{ lektor[0]["oblb_jidlo"] }}</p>
            <img src="@/assets/hvezda.png" alt="hvězda" />
          </div>
          <div class="vlastnost vlastnost2">
            <h4>Oblíbený film</h4>
            <p>{{ lektor[0]["oblb_film"] }}</p>
            <img src="@/assets/hvezda.png" alt="hvězda" />
          </div>
          <div class="vlastnost vlastnost3">
            <h4>Oblíbená kniha</h4>
            <p>{{ lektor[0]["oblb_kniha"] }}</p>
            <img src="@/assets/hvezda.png" alt="hvězda" />
          </div>
          <div class="vlastnost vlastnost4">
            <h4>Oblíbená hudba</h4>
            <p>{{ lektor[0]["oblb_hudba"] }}</p>
            <img src="@/assets/hvezda.png" alt="hvězda" />
          </div>
          <div class="vlastnost vlastnost5">
            <h4>Oblíbené sporty</h4>
            <p>{{ lektor[0]["oblb_sporty"] }}</p>
            <img src="@/assets/hvezda.png" alt="hvězda" />
          </div>
          <div class="vlastnost vlastnost6">
            <h4>Vysněné povolání</h4>
            <p>{{ lektor[0]["vysnene_povolani"] }}</p>
            <img src="@/assets/hvezda.png" alt="hvězda" />
          </div>
        </div>
      </div>

      <!-- ON MOBILE  -->
      <div v-if="onMobile" class="vlastnostMobileBox">
        <div class="vlastnostMobil">
          <div>
            <img src="@/assets/lektorDetailMobil.png" alt="" />
            <img src="@/assets/hvezda.png" alt="hvězda" />
          </div>
          <div>
            <h4>Oblíbené jídlo</h4>
          </div>
        </div>
            <p>{{ lektor[0]["oblb_jidlo"] }}</p>
        <div class="vlastnostMobil">
          <div>
            <img src="@/assets/lektorDetailMobil.png" alt="" />
            <img src="@/assets/hvezda.png" alt="hvězda" />
          </div>
          <div>
          <h4>Oblíbený film</h4>
          </div>
        </div>
          <p>{{ lektor[0]["oblb_film"] }}</p>
        <div class="vlastnostMobil">
          <div>
            <img src="@/assets/lektorDetailMobil.png" alt="" />
            <img src="@/assets/hvezda.png" alt="hvězda" />
          </div>
          <div>
          <h4>Oblíbená kniha</h4>
          </div>
        </div>
          <p>{{ lektor[0]["oblb_kniha"] }}</p>
        <div class="vlastnostMobil">
          <div>
            <img src="@/assets/lektorDetailMobil.png" alt="" />
            <img src="@/assets/hvezda.png" alt="hvězda" />
          </div>
          <div>
          <h4>Oblíbená hudba</h4>
          </div>
        </div>
          <p>{{ lektor[0]["oblb_hudba"] }}</p>
        <div class="vlastnostMobil">
          <div>
            <img src="@/assets/lektorDetailMobil.png" alt="" />
            <img src="@/assets/hvezda.png" alt="hvězda" />
          </div>
          <div>
          <h4>Oblíbené sporty</h4>
          </div>
        </div>
          <p>{{ lektor[0]["oblb_sporty"] }}</p>
        <div class="vlastnostMobil">
          <div>
            <img src="@/assets/lektorDetailMobil.png" alt="" />
            <img src="@/assets/hvezda.png" alt="hvězda" />
          </div>
          <div>
          <h4>Vysněné povolání</h4>
          </div>
        </div>
          <p>{{ lektor[0]["vysnene_povolani"] }}</p>
      </div>
    </div>

    <!-- <div class="mrakNadpis_2">
      <div>BIO</div>
    </div> -->
    <br>
    <br>
    <div class="bio">
      <div>
        <p v-html="lektor[0].lektor_text"></p>
        <h3>Co děláš ve volném čase?</h3>
        <p v-html="lektor[0].co_volny_cas"></p>
        <h3>Jaký je podle tebe ideální lektor?</h3>
        <p v-html="lektor[0].jaky_idealni_lektor"></p>
        <h3>Co tě baví na učení?</h3>
        <p v-html="lektor[0].co_bavi_na_uceni"></p>
        <h3>Proč chceš Učit Brno?</h3>
        <p v-html="lektor[0].proc_ucit_brno"></p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import img from "@/assets/userImgBlank.png";
export default {
  name: "LektorDetail",
  components: {},
  created() {
    this.lektorID = this.$route.params.id;
    document.title = this.$route.meta.title;
    this.getLektor();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    onMobile() {
      if (this.windowWidth < 1090) {
        return true;
      } else return false;
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      lektorID: "",
      lektor: ""
    };
  },
  methods: {
    getLektor() {
      axios
        .post(this.$myServerPath + "/getLektor.php", {
          id: this.lektorID,
        })
        .then((response) => {
          this.lektor = response.data;
        });
    },
    replaceByDefaultImg(e) {
      e.target.src = img;
      this.lideImgBtnDisabled = true;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.lektorDetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 220px;
}
.nadpisBlock {
  display: flex;
  align-items: end;
  justify-content: center;
}
.nadpisBlock img {
  max-height: 450px;
}
.mrakNadpis_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 850px;
  height: 250px;
  margin: 80px;
  font-size: 64px;
  font-family: SemiBold;
  color: var(--fialova);
  background: center / 100% 100% url("../assets/ucitbrno_bg_mrak01.png");
}
.mrakNadpis_1 div {
  padding: 10px 20px;
  background-color: #fff;
}
.mrakNadpis_1a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 660px;
  height: 180px;
  margin: 0 auto 60px auto;
  font-size: 42px;
  font-family: SemiBold;
  color: #fff;
  background: center / 100% 100% url("../assets/ucitbrno_bg_mrak01.png");
  transform: translate(0, -50px);
}
.mrakNadpis_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 100px;
  margin: 60px auto 60px auto;
  font-size: 32px;
  font-family: SemiBold;
  color: #fff;
  background: center / 100% 100% url("../assets/ucitbrno_bg_mrak01.png");
  transform: translate(0, 50px);
}
.lektorDetailPage {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  max-width: 1100px;
  margin-top: 460px;
  margin-left: auto;
  margin-right: auto;
}
.lektorDetail .koleckoBox {
  position: relative;
  margin-bottom: 50px;
}
.lektorDetailBlok .kolecko {
  top: 100px;
  left: 50px;
  width: 560px;
  height: 600px;
  z-index: 1;
}
.lektorDetailBlok .lektorImg {
  position: absolute;
  top: 155px;
  left: 118px;
  width: 280px;
  height: 280px;
  border-radius: 50%;
  z-index: 10;
}
.vlastnost {
  text-align: left;
}
.vlastnost p {
  text-align: left;
  max-width: 230px;
}
.vlastnost h4 {
  color: var(--fialova);
}
.vlastnostMobil h4 {
  margin-left: 10px;
  color: var(--fialova);
}
.vlastnost img {
  width: 50px;
}
.vlastnost1 {
  position: absolute;
  top: -110px;
  left: 160px;
}
.vlastnost1 img {
  position: absolute;
  top: 200px;
  left: 5px;
}
.vlastnost2 {
  position: absolute;
  top: -50px;
  left: 460px;
  width: 200px;
}
.vlastnost2 img {
  position: absolute;
  top: 140px;
  left: -150px;
}
.vlastnost3 {
  position: absolute;
  top: 160px;
  left: 630px;
  width: 200px;
  /* white-space: nowrap; */
}
.vlastnost3 img {
  position: absolute;
  top: 45px;
  left: -210px;
}
.vlastnost4 {
  position: absolute;
  top: 420px;
  left: 630px;
  width: 200px;
  /* white-space: nowrap; */
}
.vlastnost4 img {
  position: absolute;
  top: -70px;
  left: -210px;
}
.vlastnost5 {
  position: absolute;
  top: 600px;
  left: 160px;
  width: 230px;
  /* white-space: nowrap; */
}
.vlastnost5 img {
  position: absolute;
  top: -150px;
  left: 10px;
}
.vlastnost6 {
  position: absolute;
  top: 600px;
  left: 460px;
  width: 230px;
  /* white-space: nowrap; */
}
.vlastnost6 img {
  position: absolute;
  top: -150px;
  left: -145px;
}
.bio {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  margin: 30px 0 0 0;
  padding: 80px 0 0 0;
  background: center / 100% 100% no-repeat
    url("../assets/mrak-velky-fialovy-end.png");
}
.bio div {
  max-width: 900px;
  padding: 80px 20px;
  color: #fff;
}
.bio div p {
  color: #fff;
}
.v-divider {
  border-color: #fff !important;
}
.vlastnostMobil {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
}
 .vlastnostMobileBox p {
  text-align: left;
  max-width: 340px;
}
.vlastnostMobil>div>img {
}
.vlastnostMobil>div>img:nth-child(2) {
  position: absolute;
  height: 80px;
  top: 15px;
  left: 20px;
}

@media screen and (max-width: 1280px) {
  .mrakNadpis_1 {
    width: 650px;
    height: 250px;
    margin: 60px 20px;
    font-size: 54px;
    background: center / 150% 100% url("../assets/ucitbrno_bg_mrak01.png");
  }
}
@media screen and (max-width: 992px) {
  .nadpisBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mrakNadpis_1 {
    width: 750px;
    height: 250px;
    margin: 10px;
    font-size: 54px;
    background: center / 100% 100% url("../assets/ucitbrno_bg_mrak01.png");
    order: 1;
  }
  .nadpisBlock img {
    max-height: 250px;
    margin-top: 10px;
    order: 2;
  }
}
@media screen and (max-width: 860px) {
  .nadpisBox {
    overflow: hidden;
  }
}
@media (max-width: 690px) {
  .mrakNadpis_1a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 340px;
    height: 100px;
    margin: 0 auto 60px auto;
    font-size: 24px;
    background: center / 100% 100% url("../assets/ucitbrno_bg_mrak01.png");
  }
  .nadpisBlock img {
    max-height: 250px;
  }
  .mrakNadpis_1 {
    width: 340px;
    height: 250px;
    margin: 5px;
    font-size: 24px;
    background: center / 100% 100% url("../assets/ucitbrno_bg_mrak01.png");
  }
}
@media screen and (max-width: 576px) {
  .bio {
    padding: 80px 0 0 0;
    background: center / 350% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
  .bio div{
    padding: 100px 10px 0 10px;

  }
}
@media (max-width: 540px) {
  
  .bio {
    padding: 100px 0 0 0;
    background: center / 450% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
    .bio div{
      padding: 120px 10px 0 10px;
  
    }
}
</style>