<template>
  <div class="kontakt">
    <div class="mapa">
      <MapaBlock />
    </div>

    <div class="kontaktBlok">
      <div class="kontaktBox">
        <div class="kontaktPanely">
          <div v-html="obsah[0]['leftText']" class="kontaktBoxLevy">
            <!-- <p>
              <b>Kontaktní údaje:</b><br />
              +420 604 667 549 info@ucitbrno.cz
            </p>
            <p>
              <b>Adresa:</b><br />
              Kounicova 271/13, 602 00 Brno
            </p>
            <p>
              <b>Otevírací doba:</b><br />
              Pondělí–pátek: po předchozí domluvě
            </p> -->
          </div>
          <div v-html="obsah[0]['middleText']" class="kontaktBoxStred">
            <!-- <p>
              <b>Marketingové záležitosti, nabídky spolupráce a další:</b><br />
              Bc. Pavel Skoták reditel, spolumajitel, jednatel +420 728 924 213
              skotak@ucitbrno.cz
            </p>
            <p>
              <b>Lektorské záležitosti, zpětná vazba na výuku a další:</b><br />
              Mgr. Michal Vojkuvka výkonný reditel, spolumajitel, jednatel +420
              724 189 716 vojkuvka@ucitbrno.cz
            </p> -->
          </div>
          <div v-html="obsah[0]['rightText']" class="kontaktBoxPravy">
            <!-- <p>
              <b>Administrativní náležitosti:</b><br />
              Ucit Brno, s.r.o. Sídlem Šafranice 468, 679 02 Rájec-Jestrebí<br />
              IC: 07369565<br />
              DIC: CZ07369565<br />
              Nejsme plátci DPH
            </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MapaBlock from "@/components/MapaBlock.vue";
export default {
  name: "Kontakt",
  components: {
    MapaBlock,
  },
   created() {
    this.loadObsah();
    document.title = this.$route.meta.title;
  },
  data() {
    return {
      obsah: [],
    };
  },
  methods: {
     loadObsah() {
         axios.post(this.$myServerPath + "/loadObsah.php",{
        table: "Kontakt"
      })
      .then((response) => {
        this.obsah = response.data;
      });
    },
  },
};
</script>

<style>
.kontakt {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 103px;
}
.kontaktBlok {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  width: 100%;
  min-height: 200px;
  height: auto;
  margin: 50px 0;
}
.kontaktBox {
  flex: 1 1 50%;
  padding: 10px;
  background: #fff;
}
.kontaktPanely {
  display: flex;
  align-items: center;
  justify-content: center;
}
.kontaktBoxLevy {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  width: 260px;
  height: 340px;
  padding: 20px;
  background-color: var(--zluta);
  border-radius: 20px 0 0 20px;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    11px 1px 20px 5px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 10px 13px -7px #000000, 11px 1px 20px 5px rgba(0, 0, 0, 0.18);
}
.kontaktBoxLevy p {
  text-align: right;
}
.kontaktBoxStred {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 340px;
  height: 400px;
  padding: 20px;
  background-color: var(--fialova);
  color: #fff;
  border-radius: 20px;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    20px 17px 15px -3px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 10px 13px -7px #000000,
    20px 17px 15px -3px rgba(0, 0, 0, 0.29);
  z-index: 1;
}
.kontaktBoxPravy {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 260px;
  height: 340px;
  padding: 20px;
  background-color: var(--zluta);
  text-align: left;
  border-radius: 0 20px 20px 0;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    11px 1px 20px 5px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 10px 13px -7px #000000, 11px 1px 20px 5px rgba(0, 0, 0, 0.18);
}
.kontaktBoxPravy p {
  text-align: left;
}

@media (max-width: 992px) {
  .kontaktBox {
  height: 100%;
  flex: 1 1 50%;
  padding: 10px 10px;
  background: #fff;
}
  .kontaktPanely {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column
}
.kontaktBoxLevy {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 260px;
  height: 340px;
  padding: 20px;
  border-radius: 20px 20px 0 0;
}
.kontaktBoxLevy p {
  text-align: left;
}
.kontaktBoxStred {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 340px;
  height: 400px;
  padding: 20px;
  color: #fff;
  border-radius: 20px;
}
.kontaktBoxPravy {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 260px;
  height: 340px;
  padding: 20px;
  text-align: left;
  border-radius: 0 0 20px 20px;
}
.kontaktBoxPravy p {
  text-align: left;
}
}
</style>