import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueCarousel from 'vue-carousel'



Vue.use(VueCarousel);
Vue.config.productionTip = false


Vue.prototype.$devVersion = "Verze 1.00-20221018 Copyright © 2022 Doya6"
// Vue.prototype.$myServerPath = "https://mytestwww.tode.cz/UcitBrno"
// Vue.prototype.$myServerPath = "https://ucitbrno.cz"
// Vue.prototype.$myServerPath = "https://ucitbrno.cz/new"
Vue.prototype.$myServerPath = "https://www.ucitbrno.cz"

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


