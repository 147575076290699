import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Lektori from '../components/Lektori.vue'
import LektorDetail from '../components/LektorDetail.vue'
import Workshopy from '../components/Workshopy.vue'
import Mentoring from '../components/Mentoring.vue'
import Contact from '../components/Contact.vue'
import Vop from '../components/Vop.vue'
import Firemni from '../components/Firemni.vue'
import WorkshopyDetail from '../components/WorkshopyDetail.vue'
import Strana404 from '../components/Strana404.vue'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Učit Brno - Vzdělávací platforma' } 
  },
  {
    path: '/firemni-vzdelavani',
    name: 'Firemni',
    component: Firemni,
    meta: { title: 'Učit Brno - Firemní vzdělávání' } 
  },
  {
    path: '/lektorsky-tym',
    name: 'Lektori',
    component: Lektori,
    meta: { title: 'Učit Brno - Lektoři' } 
  },
  {
    path: '/lektorsky-tym/:name/:id',
    name: 'LektorDetail',
    component: LektorDetail,
    meta: { title: 'Učit Brno - Lektoři' } 
  },
  {
    path: '/workshopy-seminare',
    name: 'Workshopy-seminare',
    component: Workshopy,
    meta: { title: 'Učit Brno - Workshopy a semináře' } 
  },
  {
    path: '/workshopy-seminare/:nazev/:id',
    name: 'Workshopy-Seminare-Detail',
    component: WorkshopyDetail,
    meta: { title: 'Učit Brno - Workshopy a semináře' } 
  },
  {
    path: '/jazykovy-mentoring',
    name: 'Mentoring',
    component: Mentoring,
    meta: { title: 'Učit Brno - Jazykový mentoring' } 
  },
  {
    path: '/kontakty',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Učit Brno - Kontakt' } 
  },
  {
    path: '/vop',
    name: 'Vop',
    component: Vop,
    meta: { title: 'Učit Brno - Všeobecné podmínky' } 
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'Strana404', 
    component: Strana404,
    meta: { title: 'Stranka nenalezena' }  
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.VUE_APP_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

// const routeData = this.$router.resolve({
//   name: "routeName",
//   query: { data: "someData" },
// });
// window.open(routeData.href, "_blank");

export default router
