<template>
  <div class="kurzDetail">
    <div class="nadpisBlock">
      <div class="nadpisBox">
        <div class="mrakNadpis">
          <img src="@/assets/ucitbrno_bg_mrak01.png" alt="background nadpisu" />
          <div class="kurzDetailNadpisPopis">
            <v-row no-gutters>
              <v-col cols="6" align="start" class="">
                <div>Počet lekcí</div>
                <div>Intenzita</div>
                <div>Cena</div>
              </v-col>
              <v-divider vertical class="my-3"></v-divider>
              <v-col cols="6" align="start" class="">
                <div v-html="workshop[0]['pocetLekci']"></div>
                <div v-html="workshop[0]['intenzita']"></div>
                <div v-html="workshop[0]['cena']"></div>
              </v-col>
            </v-row>
          </div>
        </div>
        <h1 class="nadpis" v-html="workshop[0]['nazev']"></h1>
      </div>
    </div>

    <div class="kurzDetailPage">
      <div class="kurzDetailNadpisPopis_Mobile">
        <v-row no-gutters>
          <v-col cols="6" align="start" class="">
            <div>Počet lekcí</div>
            <div>Intenzita</div>
            <div>Cena</div>
          </v-col>
          <v-divider vertical class="my-3"></v-divider>
          <v-col cols="6" align="start" class="">
            <div v-html="workshop[0]['pocetLekci']"></div>
            <div v-html="workshop[0]['intenzita']"></div>
            <div v-html="workshop[0]['cena']"></div>
          </v-col>
        </v-row>
      </div>
      <!-- <div class="kurzDetailPerex">
        <p v-html="workshop[0]['uvodText']"></p>
      </div> -->

      <div class="mrakNadpis_1">
        <div v-html="workshop[0]['podnadpis1']"></div>
      </div>

      <v-row no-gutters class="podnadpis_1" justify="center" align="start">
        <v-col cols="12" class="ma-4 pa-4">
          <div>
            <p v-html="workshop[0]['uvodText']" class="px-4"></p>
          </div>
        </v-col>
      </v-row>

      <!-- <v-row no-gutters class="podnadpis_1" justify="center" align="start">
        <v-col cols="12" md="5" class="ma-4 pa-4">
          <div>
            <p v-html="workshop[0]['sloupec1']"></p>
          </div>
        </v-col>
        <v-col cols="12" md="5" class="ma-4 pa-4">
          <div>
            <p v-html="workshop[0]['sloupec2']"></p>
          </div>
        </v-col>
      </v-row> -->

      <div class="kurzDetailBlok">
        <div class="kurzDetailBox">
          <div class="kurzDetailPanely">
            <div class="kurzDetailBoxLevy">
              <img
                :src="`${$myServerPath}/WorkshopyIcons/${workshop[0]['bannerIcon1']}`"
                alt="ikona banneru 1"
              />
              <h4 v-html="workshop[0]['bannerText1']"></h4>
            </div>
            <div class="kurzDetailBoxStred">
              <img
                :src="`${$myServerPath}/WorkshopyIcons/${workshop[0]['bannerIcon2']}`"
                alt="ikona banneru 2"
              />
              <h4 v-html="workshop[0]['bannerText2']"></h4>
            </div>
            <div class="kurzDetailBoxPravy">
              <img
                :src="`${$myServerPath}/WorkshopyIcons/${workshop[0]['bannerIcon3']}`"
                alt="ikona banneru 3"
              />
              <h4 v-html="workshop[0]['bannerText3']"></h4>
            </div>
          </div>
        </div>
      </div>

      <div class="mrakNadpis_1">
        <div v-html="workshop[0]['podnadpis2']"></div>
      </div>
      <div v-html="workshop[0].listText" class="px-4"></div>
    </div>

    <!-- <div class="mrakNadpis_1">
      <div>SEMINÁŘ PŘIPRAVILI</div>
    </div>
    <div class="carouselBox">
      <CarouselKurzDetail :workshopID="workshopID" />
    </div> -->

    <div class="mrakNadpis_1">
      <div>POPTÁVKA</div>
    </div>

    <div class="poptavka">
      <div class="formPoptavka">
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">JMÉNO</div>
          <div class="formInput">
            <input v-model="jmeno" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">FIRMA</div>
          <div class="formInput">
            <input v-model="firma" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">EMAIL</div>
          <div class="formInput">
            <input v-model="email" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">TELEFON</div>
          <div class="formInput">
            <input v-model="phone" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="start" justify-md="end">
          <div class="formLabel textLabel">ZPRÁVA</div>
          <div class="formInput">
            <textarea v-model="poznamka" type="text"></textarea>
          </div>
        </v-row>
        <button v-on:click="odeslat" class="formButton">odeslat</button>
      </div>
    </div>
    <v-dialog v-model="dialog" width="360">
      <v-card>
        <v-card-text class="card_text">
          {{ dialogText }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import CarouselKurzDetail from "@/components/CarouselKurzDetail.vue";
export default {
  name: "WorkshopyDetail",
  components: {
    CarouselKurzDetail,
  },
  created() {
    this.workshopID = this.$route.params.id;
    document.title = this.$route.meta.title;
    // const routeArr = this.$route.path.split('/');

    // console.log(routeArr[2])

    this.getWorkshop();
  },
  data() {
    return {
      workshop: [],
      workshopID: "",
      currentID: "",
      index: 0,

      firma: "",
      jmeno: "",
      email: "",
      phone: "",
      poznamka: "",

      dialog: false,
      dialogText: "",
    };
  },

  methods: {
    getWorkshop() {
      axios
        .post(this.$myServerPath + "/getWorkshop.php", {
          id: this.workshopID,
        })
        .then((response) => {
          this.workshop = response.data;
        });
    },
    replaceByDefaultImg(e) {
      e.target.src = img;
      this.lideImgBtnDisabled = true;
    },
    odeslat() {
      if (this.jmeno == "" || this.email == "") {
        this.dialogText =
          "Prosím vyplňte alespoň jméno kontaktní osoby a e-mail.";
        this.dialog = true;
      } else
        axios
          .post(this.$myServerPath + "/sendMail.php", {
            firma: this.firma,
            jmeno: this.jmeno,
            email: this.email,
            phone: this.phone,
            poznamka: this.poznamka,
          })
          .then(() => {
            this.dialogText = "Formulář byl odeslán.";
            this.dialog = true;
            this.firma = "";
            this.jmeno = "";
            this.email = "";
            this.phone = "";
            this.poznamka = "";
          });
    },
  },
};
</script>

<style scoped>
.kurzDetail {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: 220px;
}
.nadpisBox {
  position: relative;
  height: 100%;
  left: 0;
}
.nadpisBox .nadpis {
  position: absolute;
  top: 180px;
  left: 250px;
  text-align: left;
  font-size: 48px;
  line-height: 1;
  padding: 5px;
  color: #fff;
  z-index: 2;
}
.mrakNadpis img {
  position: absolute;
  height: 60%;
  top: 100px;
  left: 0;
  z-index: 1;
}
.kurzDetailNadpisPopis {
  position: absolute;
  top: 150px;
  right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 340px;
  height: 180px;
  background-color: var(--fialova);
  border-radius: 20px;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    20px 17px 15px -3px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 10px 13px -7px #000000,
    20px 17px 15px -3px rgba(0, 0, 0, 0.29);
}
.kurzDetailNadpisPopis_Mobile {
  display: none;
}

.kurzDetailNadpisPopis div,
.kurzDetailNadpisPopis_Mobile div {
  padding: 10px;
  color: white;
}
.icons {
  display: flex;
  align-items: center;
  margin: 50px 0;
}
.icons img {
  width: 50px;
  margin: 10px 30px;
}
.kurzDetailPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1100px;
  margin-top: 380px;
  margin-left: auto;
  margin-right: auto;
}
.kurzDetailPerex p {
  max-width: 600px;
  padding: 10px;
}
.kurzDetailBlok {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  width: 100%;
  height: auto;
  margin: 50px 0;
}
.kurzDetailImgBox {
  height: 100%;
  flex: 0 0 50%;
  order: 1;
}
.kurzDetailImgBox Img {
  height: 100%;
  flex: 0 0 50%;
  float: left;
}
.kurzDetailBox {
  height: auto;
  flex: 1 1 50%;
  padding: 10px 10px;
  background: #fff;
  order: 2;
}
.kurzDetailPanely {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.kurzDetailBoxLevy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 150px;
  background-color: var(--zluta);
  border-radius: 20px 0 0 20px;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    11px 1px 20px 5px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 10px 13px -7px #000000, 11px 1px 20px 5px rgba(0, 0, 0, 0.18);
}
.kurzDetailBoxStred {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 200px;
  background-color: var(--fialova);
  border-radius: 20px;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    20px 17px 15px -3px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 10px 13px -7px #000000,
    20px 17px 15px -3px rgba(0, 0, 0, 0.29);
  z-index: 1;
}
.kurzDetailBoxPravy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 150px;
  background-color: var(--zluta);
  border-radius: 0 20px 20px 0;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    11px 1px 20px 5px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 10px 13px -7px #000000, 11px 1px 20px 5px rgba(0, 0, 0, 0.18);
}
.kurzDetailPanely img {
  height: 60px;
  margin-bottom: 10px;
}
.kurzDetailPanely h4 {
  color: #fff;
}
.mrakNadpis_3 {
  position: relative;
  bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  color: #fff;
  background: center / 100% 90% no-repeat
    url("../assets/ucitbrno_bg_mrak01.png");
}
.mrakNadpis_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 100px;
  margin: 50px auto 30px auto;
  font-size: 32px;
  font-family: SemiBold;
  color: #fff;
  background: center / 100% 100% url("../assets/ucitbrno_bg_mrak01.png");
}
ul {
  max-width: 600px;
  padding: 20px;
}
li {
  list-style: disc;
  margin: 20px 10px;
  text-align: left;
}
.kurzDetail .carouselBox {
  height: 660px;
  width: 100%;
  background: center / 100% 100% no-repeat url("../assets/mrak-velky-zluty.png");
}

.poptavka {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  margin: 30px 0 0 0;
  background: center / 100% 100% no-repeat
    url("../assets/mrak-velky-fialovy-end.png");
}
.formPoptavka {
  max-width: 830px;
  height: auto;
  max-height: 850px;
  margin-top: 150px;
}
.textLabel {
  text-align: end;
  margin-top: 20px;
}
.formLabel {
  width: 130px;
  text-align: end;
  font-size: 21px;
  color: #fff;
}
.formInput Input {
  width: 520px;
  height: 45px;
  margin: 10px;
  padding: 10px;
  font-size: 21px;
  color: #000;
  background-color: #fff;
  border-radius: 15px;
}
.formInput textarea {
  width: 520px;
  min-height: 200px;
  max-height: 380px;
  margin: 10px;
  padding: 10px;
  font-size: 21px;
  color: #000;
  background-color: #fff;
  border-radius: 15px;
  overflow-y: auto;
}
.formButton {
  width: 100px;
  height: 35px;
  float: right;
  margin: 10px 10px 30px;
  font-family: SemiBold;
  color: var(--fialova);
  background-color: #fff;
  border-radius: 10px;
}
.v-divider {
  border-color: #fff !important;
}

@media screen and (max-width: 1280px) {
  .kurzDetailPanely {
    flex-direction: column;
    height: auto;
  }
  .kurzDetailPanely {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .kurzDetailBoxLevy {
    width: 260px;
    height: 240px;
    padding: 20px;
    border-radius: 20px 20px 0 0;
  }
  .kurzDetailBoxStred {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 340px;
    height: 300px;
    padding: 20px;
    color: #fff;
    border-radius: 20px;
  }
  .kurzDetailBoxPravy {
    width: 260px;
    height: 240px;
    padding: 20px;
    border-radius: 0 0 20px 20px;
  }
  .kurzDetailImgBox Img {
    height: 300px;
  }
  .kurzDetail .carouselBox .carousel {
    background: center / 180% 100% no-repeat
      url("../assets/mrak-velky-zluty.png");
  }
}
@media screen and (max-width: 1360px) {
  .kurzDetailNadpisPopis {
    display: none;
  }
  .kurzDetailNadpisPopis_Mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-width: 340px;
    height: 180px;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: var(--fialova);
    border-radius: 20px;
    -webkit-box-shadow: 0px 10px 13px -7px #000000,
      20px 17px 15px -3px rgba(0, 0, 0, 0.29);
    box-shadow: 0px 10px 13px -7px #000000,
      20px 17px 15px -3px rgba(0, 0, 0, 0.29);
  }
  .poptavka {
    background: center / 150% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
}
@media screen and (max-width: 1030px) {
  .mrakNadpis img {
    position: absolute;
    height: 40%;
    top: 140px;
    left: 0;
    z-index: 1;
  }
  .nadpisBox .nadpis {
    top: 180px;
    left: 160px;
    font-size: 48px;
    line-height: 1;
  }
}
@media screen and (max-width: 860px) {
  .nadpisBox {
    overflow: hidden;
  }
  .poptavka {
    background: center / 180% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
}
@media screen and (max-width: 650px) {
  .poptavka {
    background: center / 200% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
  .mrakNadpis img {
    position: absolute;
    height: 30%;
    top: 140px;
    left: -75px;
    z-index: 1;
  }
  .nadpisBox .nadpis {
    top: 180px;
    left: 100px;
    font-size: 28px;
    line-height: 1;
  }
}
@media screen and (max-width: 576px) {
  .kurzDetail .carouselBox .carousel {
    background: center / 200% 100% no-repeat
      url("../assets/mrak-velky-zluty.png");
  }
  .poptavka {
    background: center / 250% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
  .mrakNadpis_1 {
    width: 440px;
    height: 100px;
    margin: 60px auto 60px auto;
    font-size: 28px;
    font-family: SemiBold;
    color: #fff;
    background: center / 100% 100% url("../assets/ucitbrno_bg_mrak01.png");
  }
  .poptavka {
    width: 100vw;
  }
  .formInput Input {
    width: 95vw;
  }
  .formInput textarea {
    width: 95vw;
  }
}
@media screen and (max-width: 467px) {
  .mrakNadpis_1 {
    width: 340px;
    font-size: 21px;
  }
}
</style>