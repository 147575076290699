<template>
  <div class="firemni">
    <div class="nadpisBlock">
      <div class="mrakNadpis">
        <!-- <img src="@/assets/firemniNadpis.png" alt="background nadpis" /> -->
        <h1 class="nadpis">FIREMNÍ KURZY</h1>
      </div>
    </div>

    <div class="firemniPage">
      <div font_Barlow v-html="obsah[0].uvodText"></div>
      <div class="listText" v-html="obsah[0].listText"></div>
      <div class="mrakNadpis_1">
        <div>JAK TO U NÁS CHODÍ</div>
      </div>

      <div v-if="!onMobile" class="postup">
        <div class="postupBox">
          <img src="@/assets/dot1.jpg" alt="dot" />
          <img class="sipkaHorni" src="@/assets/sipkaHorni.png" alt="šipka" />
          <p v-html="obsah[0].dot1"></p>
        </div>
        <div class="postupBox">
          <img src="@/assets/dot2.jpg" alt="dot" />
          <img class="sipkaSpodni" src="@/assets/sipkaSpodni.png" alt="šipka" />
          <p v-html="obsah[0].dot2"></p>
        </div>
        <div class="postupBox">
          <img src="@/assets/dot3.jpg" alt="dot" />
          <img class="sipkaHorni" src="@/assets/sipkaHorni.png" alt="šipka" />
          <p v-html="obsah[0].dot3"></p>
        </div>
        <div class="postupBox">
          <img src="@/assets/dot4.jpg" alt="dot" />
          <img class="sipkaSpodni" src="@/assets/sipkaSpodni.png" alt="šipka" />
          <p v-html="obsah[0].dot4"></p>
        </div>
        <div class="postupBox">
          <img src="@/assets/dot5.jpg" alt="dot" />
          <img
            class="sipkaHorni"
            src="@/assets/sipkaHorni.png"
            alt="šipka"
            style="visibility: hidden"
          />
          <p v-html="obsah[0].dot5"></p>
        </div>
      </div>

      <div v-if="onMobile" class="postupMobile">
        <div class="postupBoxMobile">
          <img src="@/assets/dot1.jpg" alt="dot" />
          <img
            class="sipkaHorniMobile"
            src="@/assets/sipkaHorni.png"
            alt="šipka"
          />
          <p v-html="obsah[0].dot1"></p>
        </div>
        <div class="postupBoxMobile">
          <img src="@/assets/dot2.jpg" alt="dot" />
          <img
            class="sipkaSpodniMobile"
            src="@/assets/sipkaSpodni.png"
            alt="šipka"
          />
          <p v-html="obsah[0].dot2"></p>
        </div>
        <div class="postupBoxMobile">
          <img src="@/assets/dot3.jpg" alt="dot" />
          <img
            class="sipkaHorniMobile"
            src="@/assets/sipkaHorni.png"
            alt="šipka"
          />
          <p v-html="obsah[0].dot3"></p>
        </div>
        <div class="postupBoxMobile">
          <img src="@/assets/dot4.jpg" alt="dot" />
          <img
            class="sipkaSpodniMobile"
            src="@/assets/sipkaSpodni.png"
            alt="šipka"
          />
          <p v-html="obsah[0].dot4"></p>
        </div>
        <div class="postupBoxMobile">
          <img src="@/assets/dot5.jpg" alt="dot" />
          <img
            class="sipkaHorniMobile"
            src="@/assets/sipkaHorni.png"
            alt="šipka"
            style="visibility: hidden"
          />
          <p v-html="obsah[0].dot5"></p>
        </div>
      </div>
    </div>

    <div class="mrakNadpis_1">
      <div>REFERENCE</div>
    </div>
    <div class="carouselBoxFiremni">
      <CarouselReference />
    </div>

    <div class="mrakNadpis_1">
      <div>POPTÁVKA</div>
    </div>

    <div class="poptavka">
      <div class="formPoptavka">
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">JMÉNO</div>
          <div class="formInput">
            <input v-model="jmeno" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">FIRMA</div>
          <div class="formInput">
            <input v-model="firma" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">EMAIL</div>
          <div class="formInput">
            <input v-model="email" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="center" justify-md="end">
          <div class="formLabel">TELEFON</div>
          <div class="formInput">
            <input v-model="phone" type="text" />
          </div>
        </v-row>
        <v-row no-gutters justify="start" align="start" justify-md="end">
          <div class="formLabel textLabel">ZPRÁVA</div>
          <div class="formInput">
            <textarea v-model="poznamka" type="text"></textarea>
          </div>
        </v-row>
        <button v-on:click="odeslat" class="formButton">odeslat</button>
      </div>
    </div>
    <v-dialog v-model="dialog" width="360">
      <v-card>
        <v-card-text class="card_text">
          {{ dialogText }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import CarouselReference from "@/components/CarouselReference.vue";
export default {
  name: "firemni",
  components: {
    CarouselReference,
  },
  created() {
    document.title = this.$route.meta.title;
    this.loadObsah();
    this.loadReference();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    onMobile() {
      if (this.windowWidth < 992) {
        return true;
      } else return false;
    },
  },
  data() {
    return {
      obsah: [],
      reference: [],
      windowWidth: window.innerWidth,

      firma: "",
      jmeno: "",
      email: "",
      phone: "",
      poznamka: "",

      dialog: false,
      dialogText: "",
    };
  },
  methods: {
    loadObsah() {
      axios
        .post(this.$myServerPath + "/loadObsah.php", {
          table: "FiremniKurzy",
        })
        .then((response) => {
          this.obsah = response.data;
        });
    },
    odeslat() {
      if (this.jmeno == "" || this.email == "") {
        this.dialogText =
          "Prosím vyplňte alespoň jméno kontaktní osoby a e-mail.";
        this.dialog = true;
      } else
        axios
          .post(this.$myServerPath + "/sendMail.php", {
            firma: this.firma,
            jmeno: this.jmeno,
            email: this.email,
            phone: this.phone,
            poznamka: this.poznamka,
          })
          .then(() => {
            this.dialogText = "Formulář byl odeslán.";
            this.dialog = true;
            this.firma = "";
            this.jmeno = "";
            this.email = "";
            this.phone = "";
            this.poznamka = "";
          });
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.firemni {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 220px;
}
.nadpisBox {
  /* position: relative;
  height: 100%;
  left: 0; */
}
.nadpisBox .nadpis {
  position: absolute;
  top: 180px;
  left: 50px;
  text-align: left;
  font-size: 48px;
  line-height: 1;
  padding: 5px;
  color: #fff;
  z-index: 2;
}
.mrakNadpis {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 500px;
  margin: 0 auto 0 auto;
  font-size: 32px;
  font-family: SemiBold;
  color: #fff;
  background: center / contain no-repeat url("../assets/firemniNadpis.png");
}
.nadpis {
  position: relative;
  top: 150px;
  font-size: 48px;
}
.firemniNadpisPopis div,
.firemniNadpisPopis_Mobile div {
  padding: 10px;
  color: white;
}
.mrakNadpis img {
  position: absolute;
  height: 100%;
  left: 0;
  z-index: 1;
}
.icons {
  display: flex;
  align-items: center;
  margin: 50px 0;
}
.icons img {
  width: 50px;
  margin: 10px 30px;
}
.firemniPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1100px;
  margin-top: 460px;
  margin-left: auto;
  margin-right: auto;
}
.firemniPage > p {
  padding: 0 20px;
}
.firemniPage > div {
  padding: 0 20px;
}
.firemniPage > div li a {
  color: var(--zluta);
}
.firemniBlok {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: auto;
  margin: 100px 0;
}
.firemniImgBox {
  height: 100%;
  flex: 0 0 50%;
  order: 1;
}
.firemniImgBox Img {
  height: 100%;
  flex: 0 0 50%;
  float: left;
}

.mrakNadpis_3 {
  position: relative;
  bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  color: #fff;
  background: center / 100% 90% no-repeat
    url("../assets/ucitbrno_bg_mrak01.png");
}
.mrakNadpis_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 100px;
  margin: 60px auto 60px auto;
  font-size: 32px;
  font-family: SemiBold;
  color: #fff;
  background: center / 100% 100% url("../assets/ucitbrno_bg_mrak01.png");
}
.listText {
  margin-top: 30px;
  text-align: left;
  margin: 20px 0;
  padding: 20px;
  max-width: 600px;
}
.postup {
  display: flex;
  justify-content: center;
  align-items: start;
  max-width: 1200px;
  height: 200px;
  margin: 100px auto 100px auto;
}
.postupBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
}
.postupBox p {
  margin: 20px 10px;
}
.sipkaHorni {
  width: 100px;
  position: relative;
  top: -120px;
  left: 80px;
}
.sipkaSpodni {
  width: 100px;
  position: relative;
  top: 0px;
  left: 80px;
}
.postupMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin: 0 auto 0 auto;
}
.postupBoxMobile {
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
}
.postupBoxMobile p {
  margin: 10px;
}
.sipkaHorniMobile {
  width: 100px;
  position: relative;
  top: 60px;
  left: 150px;
  transform: rotate(90deg);
}
.sipkaSpodniMobile {
  width: 100px;
  position: relative;
  top: 60px;
  left: -160px;
  transform: rotate(90deg);
}
.firemni .carouselBoxFiremni {
  /* height: 550px; */
  width: 100%;
  /* background-color: var(--fialova); */
  /* background: center / 100% 100% no-repeat
    url("../assets/mrak-velky-fialovy.png"); */
}
.poptavka {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  margin: 30px 0 0 0;
  background: center / 100% 100% no-repeat
    url("../assets/mrak-velky-zluty-end.png");
}
.formPoptavka {
  max-width: 830px;
  height: auto;
  max-height: 850px;
  margin-top: 150px;
}
.textLabel {
  text-align: end;
  margin-top: 20px;
}
.formLabel {
  text-align: end;
  width: 100px;
  font-size: 21px;
  color: #fff;
}
.formInput Input {
  width: 520px;
  height: 45px;
  margin: 10px;
  padding: 10px;
  font-size: 21px;
  color: #000;
  background-color: #fff;
  border-radius: 15px;
}
.formInput textarea {
  width: 520px;
  min-height: 200px;
  max-height: 380px;
  margin: 10px;
  padding: 10px;
  font-size: 21px;
  color: #000;
  background-color: #fff;
  border-radius: 15px;
  overflow-y: auto;
}
.formButton {
  width: 100px;
  height: 35px;
  float: right;
  margin: 10px 10px 30px;
  font-family: SemiBold;
  color: var(--fialova);
  background-color: #fff;
  border-radius: 10px;
}
.v-divider {
  border-color: #fff !important;
}

@media screen and (max-width: 1260px) {
  .firemni .carouselBoxFiremni .carousel {
    /* background: center / 180% 100% no-repeat
      url("../assets/mrak-velky-zluty.png"); */
  }
}
@media screen and (max-width: 992px) {
  .poptavka {
    background: center / 150% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
}
@media screen and (max-width: 860px) {
  .nadpisBox {
    overflow: hidden;
  }
  .poptavka {
    background: center / 180% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
  .mrakNadpis {
    width: 500px;
  }
  .nadpis {
    top: 120px;
  }
}
@media screen and (max-width: 650px) {
  .poptavka {
    background: center / 200% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
}
@media screen and (max-width: 576px) {
  .firemni .carouselBoxFiremni .carousel {
    /* background: center / 200% 100% no-repeat
      url("../assets/mrak-velky-zluty.png"); */
  }
  .poptavka {
    background: center / 250% 100% no-repeat
      url("../assets/mrak-velky-fialovy-end.png");
  }
  .mrakNadpis {
    width: 360px;
  }
  .nadpis {
    top: 90px;
  }
  .mrakNadpis_1 {
    width: 340px;
    height: 100px;
    margin: 60px auto 60px auto;
    font-size: 32px;
    font-family: SemiBold;
    color: #fff;
    background: center / 100% 100% url("../assets/ucitbrno_bg_mrak01.png");
  }
  .poptavka {
    width: 100vw;
  }
  .formInput Input {
    width: 95vw;
  }
  .formInput textarea {
    width: 95vw;
  }
  .listText {
  margin: 20px 10px;
}
}
</style>