<template>
  <div id="navbar">
    <router-link to="/"
      ><img id="logo" src="../assets/logoMenu.png" alt="logo"
    /></router-link>

    <div v-if="!onMobile" id="navbar-right">
      <router-link class="home_icon" to="/"
        ><img src="" alt="home icon"
      /></router-link>
      <router-link to="/firemni-vzdelavani">Firemní kurzy</router-link>
      <router-link to="/workshopy-seminare">Workshopy & Semináře</router-link>
      <router-link to="/jazykovy-mentoring">Jazykový mentoring</router-link>
      <router-link to="/lektorsky-tym">Lektoři</router-link>
      <router-link to="/kontakty">Kontakt</router-link>
    </div>

    <!-- ON MOBILE -->
    <div v-if="onMobile" @click="showSideBar()" class="side_bar_icon">
      <img src="@/assets/sideMenuIcon.png" alt="side menu" />
    </div>

    <div v-if="drawer && onMobile">
      <v-navigation-drawer
        class="drawer"
        v-model="drawer"
        absolute
        right
        height="auto"
        width="auto"
      >
        <v-list nav>
          <v-list-item-group v-model="group" class="side_menu">
            <router-link to="/">
              <v-list-item>
                <v-list-item-title>Home</v-list-item-title>
              </v-list-item>
            </router-link>
            
            <router-link to="/firemni-vzdelavani">
              <v-list-item>
                <v-list-item-title>Firemní kurzy</v-list-item-title>
              </v-list-item>
            </router-link>

            <router-link to="/workshopy-seminare">
              <v-list-item>
                <v-list-item-title>Workshopy</v-list-item-title>
              </v-list-item>
            </router-link>

            <router-link to="/jazykovy-mentoring">
              <v-list-item>
                <v-list-item-title>Jazykový mentoring</v-list-item-title>
              </v-list-item>
            </router-link>
            
            <router-link to="/lektorsky-tym">
              <v-list-item>
                <v-list-item-title>Lektoři</v-list-item-title>
              </v-list-item>
            </router-link>

            <router-link to="/kontakty">
              <v-list-item>
                <v-list-item-title>Kontakt </v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  created() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    onMobile() {
      if (this.windowWidth < 1020) {
        return true;
      } else return false;
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      drawer: false,
      group: null,
    };
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    showSideBar() {
      this.drawer = true;
    },
  },
};
window.onscroll = function () {
  scrollFunction();
};
function scrollFunction() {
  if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
    document.getElementById("navbar").style.padding = "0 30px";
    document.getElementById("logo").style.fontSize = "25px";
  } else {
    document.getElementById("navbar").style.padding = "20px 30px";
    document.getElementById("logo").style.fontSize = "35px";
  }
}
</script>

<style scoped>
#navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background-color: var(--fialovaMenu);
  padding: 20px 50px; /* Large padding which will shrink on scroll (using JS) */
  transition: 0.4s; /* Adds a transition effect when the padding is decreased */
  position: fixed; /* Sticky/fixed navbar */
  width: 100%;
  top: 0; /* At the top */
  z-index: 99;
}

/* Style the navbar links */
#navbar a {
  float: left;
  color: white;
  text-align: center;
  padding: 0 20px;
  text-decoration: none;
  font-size: 24px;
  border-radius: 4px;
}

/* Style the logo */
#navbar #logo {
  width: 180px;
  margin: 5px 0;
}
#navbar .home_icon {
  display: flex;
  align-items: center;
}
#navbar .home_icon img {
  content: url(../assets/home_white.png);
  align-self: center;
}
#navbar .home_icon.router-link-exact-active {
  content: url(../assets/home.png);
  align-self: center;
}

#navbar a:hover {
  color: var(--zluta) !important;
  transition: 0.5s;
}
#navbar a.router-link-exact-active.router-link-active {
  color: var(--zluta) !important;
}
#navbar-right {
  display: flex;
  align-items: center;
  float: right;
}
.side_bar_icon img {
  width: 60px;
  margin: 0 50px;
}
.drawer {
  position: fixed;
}
.v-navigation-drawer {
  padding: 0px;
}
.side_menu {
  padding: 0;
  background-color: var(--fialovaMenu);
}
.v-list {
  padding: 0;
}
.v-list-item__title {
  font-size: 24px;
  padding: 40px 50px;
}

#app
  > div
  > div
  > div.navbar
  > div:nth-child(4)
  > aside
  > div.v-navigation-drawer__content
  > div {
  padding: 0;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: white !important;
}

@media screen and (max-width: 1060px) {
  #navbar {
    padding: 20px 20px;
  }
}
@media screen and (max-width: 1020px) {
  #navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px !important; /* Use !important to make sure that JavaScript doesn't override the padding on small screens */
  }
  #navbar a {
    float: none;
    display: block;
    text-align: left;
  }
  .side_bar_icon img {
  width: 60px;
  margin: 0 10px;
}
}
</style>
